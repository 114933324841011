import { CheckOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { Col, Menu, Row } from 'antd'
import { ObjectId } from 'bson'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

import { UnitKind } from '@lms-shared-patterns/models'
import {
  ContentUnit,
  PdfUnit,
  PublicUnitQuery,
  Unit,
} from 'apps/lms-front/src/generated/graphql'
import { Container } from 'apps/lms-front/src/modules/shared/layout/Layout.style'

import { LoadScreen, LoadSection } from '../../../core/components/LoadScreen'
import { getParentRoute } from '../../../core/routes/router'
import { logActivity } from '../../../shared/helpers/log-activity'
import { PageProps } from '../../../shared/interfaces/page.interface'
import { Header } from '../../components/header'

import PUBLIC_UNIT_QUERY from './../../queries/public-unit.graphql'
import { ContentUnitViewer } from './content-unit-viewer/ContentUnitViewer'
import { PDFUnitViewer } from './pdf-unit-viewer/PdfUnitViewer'
import {
  QuizRemoteUnitViewer,
  QuizUnitViewer,
} from './quiz-unit-viewer/QuizUnitViewer'
import {
  SurveyRemoteUnitViewer,
  SurveyUnitViewer,
} from './survey-unit-viewer/SurveyUnitViewer'
import { Content, SideMenu, UnitFocusLayout } from './UnitViewer.style'
import { VideoUnitViewer } from './video-unit-viewer/VideoUnitViewer'

export const UnitViewer = ({ route }: PageProps) => {
  const navigate = useNavigate()
  const params = useParams()
  const { unit: id, certification: certification_id, event_id } = params
  const parent = getParentRoute(route, params, certification_id ? 1 : 2)

  const canNavigateToUnit = (
    sequentiality = false,
    target: Unit | undefined,
    next?: string
  ) => {
    if (!sequentiality || event_id) return true
    const isNextUnit = target?._id
      ? new ObjectId(target._id as string).equals(next || '')
      : target?._id === next
    const targetCompleted = !!target?.my_activity?.completed
    return isNextUnit || targetCompleted
  }

  const [loading, setLoading] = useState(false)

  const { data, error, refetch } = useQuery<PublicUnitQuery>(
    PUBLIC_UNIT_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: { id, event_id },
    }
  )

  const unit = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    return data?.fetchUnitById as PublicUnitQuery['fetchUnitById'] & {
      __typename:
        | 'ContentUnit'
        | 'VideoUnit'
        | 'PDFUnit'
        | 'QuizUnit'
        | 'SurveyUnit'
    }
  }, [data])

  const course = useMemo(() => {
    return unit?.course
  }, [unit])

  const menuItems = useMemo(() => {
    if (!course) return []
    return [...(course?.translation.contents || [])]
      .sort((a, b) => a.order - b.order)
      .map((section) => {
        return {
          key: section._id,
          label: section.title,
          children: [...section.units]
            .sort((a, b) =>
              a.__typename !== 'Unit' && b.__typename !== 'Unit'
                ? a.order - b.order
                : 0
            )
            .map((u) => {
              if (u.__typename === 'ContentUnit')
                return {
                  ...u,
                  translation: u.contentUnitTranslation,
                }

              if (u.__typename === 'PDFUnit')
                return {
                  ...u,
                  translation: u.pdfUnitTranslation,
                }

              if (u.__typename === 'VideoUnit')
                return {
                  ...u,
                  translation: u.videoUnitTranslation,
                }

              if (u.__typename === 'QuizUnit')
                return {
                  ...u,
                  translation: u.quizUnitTranslation,
                }

              if (u.__typename === 'SurveyUnit')
                return {
                  ...u,
                  translation: u.surveyUnitTranslation,
                }

              return u
            })
            .map((u) => {
              const disabled = !canNavigateToUnit(
                course?.my_activity?.certification_type?.sequentiality || false,
                u as Unit,
                course?.firstUnit || undefined
              )
              if (u.__typename === 'Unit') {
                return undefined
              }
              return {
                disabled,
                key: u._id,
                label: u.translation?.name || u.name,
                icon: u.my_activity?.completed ? <CheckOutlined /> : undefined,
              }
            })
            .filter(Boolean),
        }
      })
  }, [course])

  const openMenuSections = useMemo(() => {
    return menuItems
      ?.filter((section) => section.children.find((u) => u?.key === unit?._id))
      .map((i) => i.key)
  }, [menuItems, unit])

  const navigateToUnit = useCallback(
    (id: string) => {
      setLoading(true)
      refetch({ id })
        .then(() => {
          setLoading(false)
          navigate(`./../${id}`)
        })
        .finally(() => setLoading(false))
    },
    [navigate, refetch]
  )

  const renderedUnit = useMemo(() => {
    if (!unit) return null

    switch (unit.__typename) {
      case UnitKind.CONTENT: {
        return (
          <ContentUnitViewer
            unit={unit as ContentUnit}
            navigate={navigate}
            navigateToUnit={navigateToUnit}
            parent={parent}
          />
        )
      }
      case UnitKind.VIDEO: {
        return (
          <VideoUnitViewer
            unit={unit}
            navigate={navigate}
            navigateToUnit={navigateToUnit}
            allowChangingPlaybackRate={
              !(
                course?.my_activity?.certification_type?.disable_playbackrate ||
                false
              ) || !!unit.my_activity?.completed
            }
            refetchUnit={async () => await refetch()}
            parent={parent}
          />
        )
      }
      case UnitKind.PDF: {
        return (
          <PDFUnitViewer
            unit={unit as PdfUnit}
            navigate={navigate}
            navigateToUnit={navigateToUnit}
            parent={parent}
          />
        )
      }
      case UnitKind.QUIZ: {
        return event_id ? (
          <QuizRemoteUnitViewer
            unit={unit}
            navigate={navigate}
            navigateToUnit={navigateToUnit}
            event_id={event_id}
            parent={parent}
          />
        ) : (
          <QuizUnitViewer
            unit={unit}
            navigate={navigate}
            navigateToUnit={navigateToUnit}
            refetchUnit={async () => await refetch()}
            parent={parent}
          />
        )
      }
      case UnitKind.SURVEY: {
        return event_id ? (
          <SurveyRemoteUnitViewer
            unit={unit}
            navigate={navigate}
            navigateToUnit={navigateToUnit}
            event_id={event_id}
            parent={parent}
          />
        ) : (
          <SurveyUnitViewer
            unit={unit}
            navigate={navigate}
            navigateToUnit={navigateToUnit}
            refetchUnit={async () => await refetch()}
            parent={parent}
          />
        )
      }
      default: {
        return null
      }
    }
  }, [unit, navigate, navigateToUnit])

  useEffect(() => {
    if (
      !canNavigateToUnit(
        course?.my_activity?.certification_type?.sequentiality || false,
        unit as unknown as Unit,
        course?.firstUnit || undefined
      )
    ) {
      if (course?.firstUnit) navigateToUnit(course?.firstUnit)
      else navigate('/')
    } else if (unit)
      logActivity({ unit_id: unit?._id, certification_id, event_id }).then(
        () => {
          if (certification_id) {
            navigate(parent)
          } else {
            refetch()
          }
        }
      )
  }, [
    course,
    unit,
    certification_id,
    parent,
    navigateToUnit,
    navigate,
    refetch,
  ])

  if (error) return <Navigate to={parent} />
  if (!unit) return <LoadScreen />

  return (
    <>
      <Helmet>
        <title>{course?.translation.name}</title>
      </Helmet>
      <Header
        title={course?.translation.name}
        onBack={() =>
          navigate(`${parent}/${encodeURIComponent(course?.slug || '')}`)
        }
      />
      <Container>
        <UnitFocusLayout>
          <Content style={{ flex: 1, backgroundColor: '#FFF' }}>
            <Row justify="center" style={{ flex: 1 }} gutter={24}>
              {loading ? (
                <Col md={20} flex={1}>
                  <>
                    <LoadSection />
                  </>
                </Col>
              ) : (
                renderedUnit
              )}
            </Row>
          </Content>
          <SideMenu width="25%">
            {unit && (
              <Menu
                key={unit._id}
                mode="inline"
                defaultOpenKeys={openMenuSections}
                selectedKeys={[unit._id]}
                style={{ height: '100%' }}
                items={menuItems}
                onClick={({ key }) => navigateToUnit(key)}
              />
            )}
          </SideMenu>
        </UnitFocusLayout>
      </Container>
    </>
  )
}
