import { useMutation } from '@apollo/client'
import { t } from '@lingui/macro'
import { Input, Modal, message } from 'antd'
import { useState } from 'react'

import {
  PublicUnitQuery,
  SubmitUnitQuestionMutation,
} from 'apps/lms-front/src/generated/graphql'

import SUBMIT_UNIT_QUESTION_MUTATION from '../../../../mutations/submit-unit-question.graphql'
type VideoUnit = PublicUnitQuery['fetchUnitById'] & {
  __typename: 'VideoUnit'
}

type VideoQuestionModalProps = {
  open: boolean
  onClose: () => unknown
  unit: VideoUnit
}

export const VideoQuestionModal = ({
  open,
  onClose,
  unit,
}: VideoQuestionModalProps) => {
  const [question, setQuestion] = useState<string>('')
  const [submitQuestion, { loading: submittingQuestion }] =
    useMutation<SubmitUnitQuestionMutation>(SUBMIT_UNIT_QUESTION_MUTATION, {
      notifyOnNetworkStatusChange: true,
    })

  return (
    <Modal
      title={t({
        id: 'unit.viewer.video.question_modal.title',
        message: 'Een vraag stellen over dit opleidingsonderdeel',
      })}
      centered
      open={open}
      onOk={() => {
        submitQuestion({
          variables: {
            id: unit._id,
            question,
          },
        })
          .then(() => {
            message.info(
              t({
                id: 'unit.viewer.video.question_modal.success',
                message: 'Je vraag werd succesvol doorgestuurd!',
              })
            )
            setQuestion('')
            onClose()
          })
          .catch((error) => {
            console.error(error)
            message.error(
              t({
                id: 'unit.viewer.video.question_modal.failed',
                message:
                  'Er ging iets mis bij het doorsturen van je vraag. Probeer het later opnieuw.',
              })
            )
          })
      }}
      okText={t({
        id: 'unit.viewer.video.question_modal.submit',
        message: 'Vraag verzenden',
      })}
      onCancel={() => onClose()}
      okButtonProps={{
        disabled: !question || question === '',
        loading: submittingQuestion,
      }}
      cancelText={t({
        id: 'action.cancel',
        message: 'Annuleren',
      })}
    >
      <Input.TextArea
        autoSize
        placeholder={t({
          id: 'unit.viewer.video.question_modal.placeholder',
          message: 'Je vraag',
        })}
        onChange={(evt) => setQuestion(evt.target.value)}
        value={question}
        style={{ minHeight: 100 }}
      />
    </Modal>
  )
}
