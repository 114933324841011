export const Arrow = () => (
  <svg width="15" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 8h10M8.125 3.625 12.5 8l-4.375 4.375"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
