import {
  FileTextOutlined,
  FilePdfOutlined,
  PlayCircleOutlined,
  QuestionCircleOutlined,
  ExclamationCircleOutlined,
  ImportOutlined,
} from '@ant-design/icons'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  Button,
  Dropdown,
  Menu,
  Modal,
  notification,
  Select,
  Space,
} from 'antd'
import { test } from 'fuzzyjs'
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import {
  CourseQuery,
  CourseSection,
  CoursesQuery,
  ImportUnitMutation,
  Unit,
} from 'apps/lms-front/src/generated/graphql'

import { SectionUnitTimeline } from '../section-unit-timeline'

import IMPORT_UNIT_MUTATION from './../../mutations/import-unit.graphql'
import COURSE_QUERY from './../../queries/course.graphql'
import COURSES_QUERY from './../../queries/courses.graphql'

interface Props {
  className?: string
  courseId: string
  section: CourseSection
  draggable?: boolean
}

const AddSectionDropdown = ({
  courseId,
  sectionId,
}: {
  courseId: string
  sectionId: string
}) => {
  const [importModalVisible, setImportModalVisible] = useState<boolean>(false)

  return (
    <>
      <Dropdown
        overlay={
          <Menu
            items={[
              {
                key: 'content',
                label: (
                  <Link
                    className="btn-link"
                    to={`/courses/${courseId}/${sectionId}/add/content`}
                  >
                    <Trans id="course.edit_contents.add.content">Inhoud</Trans>
                  </Link>
                ),
                icon: <FileTextOutlined />,
              },
              {
                key: 'pdf',
                label: (
                  <Link
                    className="btn-link"
                    to={`/courses/${courseId}/${sectionId}/add/pdf`}
                  >
                    <Trans id="course.edit_contents.add.pdf">PDF</Trans>
                  </Link>
                ),
                icon: <FilePdfOutlined />,
              },
              {
                key: 'video',
                label: (
                  <Link
                    className="btn-link"
                    to={`/courses/${courseId}/${sectionId}/add/video`}
                  >
                    <Trans id="course.edit_contents.add.video">Video</Trans>
                  </Link>
                ),
                icon: <PlayCircleOutlined />,
              },
              {
                key: 'quiz',
                label: (
                  <Link
                    className="btn-link"
                    to={`/courses/${courseId}/${sectionId}/add/quiz`}
                  >
                    <Trans id="course.edit_contents.add.quiz">Toets</Trans>
                  </Link>
                ),
                icon: <QuestionCircleOutlined />,
              },
              {
                key: 'survey',
                label: (
                  <Link
                    className="btn-link"
                    to={`/courses/${courseId}/${sectionId}/add/survey`}
                  >
                    <Trans id="course.edit_contents.add.survey">Enquête</Trans>
                  </Link>
                ),
                icon: <ExclamationCircleOutlined />,
              },
              {
                type: 'divider',
              },
              {
                key: 'import',
                label: t({
                  id: 'course.edit_contents.add.import',
                  message: 'Importeren uit opleiding',
                }),
                icon: <ImportOutlined />,
                onClick: () => setImportModalVisible(true),
              },
            ]}
          ></Menu>
        }
      >
        <Button type={'primary'}>
          <Trans id="course.edit_contents.add_unit">
            Voeg lesonderdeel toe
          </Trans>
        </Button>
      </Dropdown>
      <ImportModal
        visible={importModalVisible}
        courseId={courseId}
        sectionId={sectionId}
        onCancel={() => setImportModalVisible(false)}
      />
    </>
  )
}

export const EditSectionContents: FC<Props> = ({
  courseId,
  section,
  draggable = false,
}) => {
  return (
    <>
      {
        <SectionUnitTimeline
          id={section._id}
          units={section.units}
          editable
          draggable={draggable}
        />
      }
      {
        <Space>
          <AddSectionDropdown courseId={courseId} sectionId={section._id} />
        </Space>
      }
    </>
  )
}

interface ImportModalProps {
  visible: boolean
  courseId: string
  sectionId: string
  onCancel: () => void
}

export const ImportModal: FC<ImportModalProps> = ({
  visible,
  courseId: target_course_id,
  sectionId: target_section_id,
  onCancel,
}) => {
  const [fetch, { data: coursesData, loading: coursesLoading }] =
    useLazyQuery<CoursesQuery>(COURSES_QUERY, {
      notifyOnNetworkStatusChange: true,
      variables: {
        query: '',
        page: 1,
        limit: 50000,
      },
    })

  useEffect(() => {
    if (visible && !coursesData) fetch()
  }, [visible, fetch, coursesData])

  const [fetchCourseContent, { data, loading }] = useLazyQuery<CourseQuery>(
    COURSE_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        query: '',
        page: 1,
        limit: 50000,
      },
    }
  )

  const [importUnit, { loading: importLoading }] =
    useMutation<ImportUnitMutation>(IMPORT_UNIT_MUTATION, {
      notifyOnNetworkStatusChange: true,
      variables: {
        query: '',
        page: 1,
        limit: 50000,
      },
    })

  const [courseId, setCourseId] = useState<string>()
  const [unitId, setUnitId] = useState<string>()

  const handleOk = () => {
    importUnit({
      variables: {
        unit_id: unitId,
        target_course_id,
        target_section_id,
      },
    })
      .then(() => {
        notification.success({
          message: t({
            id: 'course.edit_contents.import.success',
            message: 'Opleidingsonderdeel succesvol geïmporteerd.',
          }),
        })
        setCourseId(undefined)
        setUnitId(undefined)
        onCancel()
      })
      .catch(() => {
        notification.error({
          message: t({
            id: 'course.edit_contents.import.failed',
            message: 'Er liep iets fout. Probeer later opnieuw.',
          }),
        })
      })
  }

  return (
    <Modal
      title={t({
        id: 'modal.import_unit.title',
        message: 'Lesonderdeel importeren',
      })}
      open={visible}
      okText={t({
        id: 'action.import',
        message: 'Importeren',
      })}
      okButtonProps={{
        disabled: !(unitId && courseId),
        loading: importLoading,
      }}
      cancelText={t({
        id: 'action.cancel',
        message: 'Annuleren',
      })}
      onOk={handleOk}
      onCancel={() => {
        setCourseId(undefined)
        setUnitId(undefined)
        onCancel()
      }}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Select
          loading={coursesLoading}
          showSearch
          placeholder={t({
            id: 'modal.import_unit.select_course',
            message: 'Selecteer of zoek een opleiding',
          })}
          optionFilterProp="children"
          onClear={() => {
            setCourseId(undefined)
            setUnitId(undefined)
          }}
          onChange={(value) => {
            setCourseId(value)
            fetchCourseContent({
              variables: {
                id: value,
              },
            })
            setUnitId(undefined)
          }}
          value={courseId}
          filterOption={(input, option) => {
            if (!option) return false
            return test(input, option?.label)
          }}
          options={coursesData?.fetchCourses?.results.map((item) => ({
            label: item.name,
            value: item._id,
          }))}
          style={{ width: '100%' }}
        />

        {courseId && (
          <Select
            loading={loading}
            showSearch
            placeholder={t({
              id: 'modal.import_unit.select_unit',
              message: 'Selecteer of zoek een onderdeel',
            })}
            optionFilterProp="children"
            onChange={(value) => setUnitId(value)}
            filterOption={(input, option) => {
              if (!option) return false
              return test(input, option?.label)
            }}
            value={unitId}
            options={data?.fetchCourseById.contents
              .reduce<CourseQuery['fetchCourseById']['contents'][0]['units']>(
                (prev, cur) => [...prev, ...cur.units],
                []
              )
              .map((unit) => ({
                label: (unit as Unit)?.name,
                value: (unit as Unit)?._id,
              }))}
            style={{ width: '100%' }}
          />
        )}
      </Space>
    </Modal>
  )
}
