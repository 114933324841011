import { SmileOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { Trans } from '@lingui/macro'
import { Result, Spin } from 'antd'
import { useState } from 'react'
import { Navigate } from 'react-router-dom'

import { EventActionType } from '@lms-shared-patterns/models/event-type.model'
import { MyActiveEventsQuery } from 'apps/lms-front/src/generated/graphql'
import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'
import { useSocket } from 'apps/lms-front/src/modules/shared/hooks/use-socket.hook'

import { PageProps } from '../../../shared/interfaces/page.interface'
import MY_ACTIVE_EVENTS_QUERY from '../../queries/my-active-events.graphql'
import { ActionPage } from '../action/ActionPage'

export type Action = {
  event_id: string
  unit_id: string
  expires: Date
  duration?: number
  type: EventActionType
}

export const EventRemoteViewer = (props: PageProps) => {
  const { data: events, loading } = useQuery<MyActiveEventsQuery>(
    MY_ACTIVE_EVENTS_QUERY
  )
  const [actions, setActions] = useState<Action[] | null>(null)

  const { token } = useAuth()

  const { connected, emit } = useSocket('/api/event/ws', {
    token,
    events: {
      eventActionRegistered: (value: Action) => {
        setActions((actions) =>
          actions
            ? [
                ...actions.filter(
                  (action) =>
                    action.event_id !== value.event_id &&
                    action.unit_id !== value.unit_id
                ),
                value as Action,
              ]
            : [value as Action]
        )
      },
      eventActionExpired: (value: Action) => {
        props.setPageProgressBar?.(0)
        setActions(
          (actions) =>
            [...(actions || [])]?.filter(
              (action) =>
                action.event_id !== value.event_id ||
                action.unit_id !== value.unit_id
            )
        )
      },
    },
  })

  if (
    !loading &&
    events?.myActiveEvents &&
    events.myActiveEvents.length === 0
  ) {
    return <Navigate to="/join" />
  }

  if (actions?.length) {
    return (
      <ActionPage
        key={`${actions[0].unit_id}_${actions[0].event_id}`}
        action={actions[0]}
        onFinish={() => {
          setActions((actions) => (actions ? actions.slice(1) : null))
        }}
        emitEvent={emit}
        {...props}
      />
    )
  }

  return connected ? (
    <Result
      icon={<SmileOutlined />}
      title={
        <Trans id="companion.idle.title">Je bent er helemaal klaar voor!</Trans>
      }
      subTitle={
        <Trans id="companion.idle.subtitle">
          We laten je weten wanneer er een opdracht voor je klaarstaat.
        </Trans>
      }
    />
  ) : (
    <Result
      icon={<Spin size="large" />}
      title={<Trans id="companion.connecting.title">Connecteren...</Trans>}
      subTitle={
        <Trans id="companion.connecting.subtitle">
          Als dit te lang duurt, probeer dan de pagina te herladen.
        </Trans>
      }
    />
  )
}
