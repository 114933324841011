import { t } from '@lingui/macro'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'

import { ArticlePage } from '../../articles/pages/article/Article'
import { ArticleEdit } from '../../articles/pages/article-edit/ArticleEdit'
import { ArticleOverview } from '../../articles/pages/article-overview/ArticleOverview'
import { ArticleTranslation } from '../../articles/pages/article-translation/ArticleTranslation'
import { LanguageSelector } from '../../auth/pages/language-selector/LanguageSelector'
import { Login } from '../../auth/pages/login'
import { ResetPassword } from '../../auth/pages/reset-password/ResetPassword'
import { TokenHandler } from '../../auth/pages/token/TokenHandler'
import { Branch } from '../../branch/pages/branch/Branch'
import { BranchRoleDetail } from '../../branch/pages/roles/role-detail/BranchRoleDetail'
import { BranchRuleDetail } from '../../branch/pages/rules/rule-detail/BranchRuleDetail'
import { BranchRuleViewer } from '../../branch/pages/rules/rule-viewer/BranchRuleViewer'
import { Assigned } from '../../courses/pages/assigned/Assigned'
import { CourseEdit } from '../../courses/pages/course-edit/CourseEdit'
import { CourseEditContents } from '../../courses/pages/course-edit-contents/CourseEditContents'
import { CourseTranslation } from '../../courses/pages/course-translation/CourseTranslation'
import { CourseViewer } from '../../courses/pages/course-viewer/CourseViewer'
import { Courses } from '../../courses/pages/courses'
import { ImportCourses } from '../../courses/pages/import-courses/ImportCourses'
import { Likes } from '../../courses/pages/likes/Likes'
import { EventHostViewer } from '../../events/pages/event-host-viewer/EventHostViewer'
import { EventRemoteViewer } from '../../events/pages/event-remote-viewer/EventRemoteViewer'
import { JoinEventPage } from '../../events/pages/join-event/JoinEventPage'
import { EventOverview } from '../../events/pages/overview/EventOverview'
import { Extension } from '../../extensions/Extension'
import { Dashboard } from '../../home/pages/dashboard/Dashboard'
import { Welcome } from '../../home/pages/welcome/Welcome'
import { JobOverview } from '../../jobs/pages/job-overview/JobOverview'
import { JobViewer } from '../../jobs/pages/job-viewer/JobViewer'
import { Reports } from '../../reports/pages/reports/Reports'
import { ImportBranches } from '../../settings/pages/branches/import/ImportBranches'
import { CategoryTranslation } from '../../settings/pages/categories/CategoryTranslation'
import { FieldTranslation } from '../../settings/pages/fields/FieldTranslation'
import { GeneralSettings } from '../../settings/pages/general/General'
import { RoleDetail } from '../../settings/pages/roles/role-detail/RoleDetail'
import { RuleDetail } from '../../settings/pages/rules/rule-detail/RuleDetail'
import { CourseTypeTranslation } from '../../settings/pages/types/CourseTypeTranslation'
import { ExportUsers } from '../../settings/pages/users/export-users/ExportUsers'
import { ImportBranchUsers } from '../../settings/pages/users/import-branch-users/ImportBranchUsers'
import { ImportUsers } from '../../settings/pages/users/import-users/ImportUsers'
import { FocusLayout } from '../../shared/focus-layout'
import { CourseAddUnit } from '../../units/pages/add-unit/AddUnit'
import { EditUnit } from '../../units/pages/edit-unit/EditUnit'
import { UnitViewer } from '../../units/pages/unit-viewer'
import { Certificates } from '../../user/pages/certificates'
import { Profile } from '../../user/pages/profile'
import { Logout } from '../components/LogoutAction'

import { Route } from './route.interface'

const courses = (): Route[] => [
  {
    path: '/:slug',
    component: CourseViewer,
    container: false,
  },
  {
    path: '/:id',
    label: t({
      id: 'routes.label.course',
      message: 'Opleiding',
    }),
    component: CourseViewer,
    container: false,
    routes: [
      {
        path: '/edit',
        label: t({
          id: 'routes.label.edit-course',
          message: 'Opleiding bewerken',
        }),
        component: CourseEdit,
      },
      {
        path: '/edit-content',
        label: t({
          id: 'routes.label.edit-course-content',
          message: 'Opleidingsinhoud bewerken',
        }),
        component: CourseEditContents,
      },
      {
        path: '/:section/add/content',
        label: t({
          id: 'routes.label.add-content',
          message: 'Inhoud toevoegen',
        }),
        component: CourseAddUnit,
      },
      {
        path: '/:section/add/pdf',
        label: t({
          id: 'routes.label.add-pdf',
          message: 'PDF toevoegen',
        }),
        component: CourseAddUnit,
      },
      {
        path: '/:section/add/video',
        label: t({
          id: 'routes.label.add-video',
          message: 'Video toevoegen',
        }),
        component: CourseAddUnit,
      },
      {
        path: '/:section/add/quiz',
        label: t({
          id: 'routes.label.add-quiz',
          message: 'Toets toevoegen',
        }),
        component: CourseAddUnit,
      },
      {
        path: '/:section/add/survey',
        label: t({
          id: 'routes.label.add-survey',
          message: 'Enquête toevoegen',
        }),
        component: CourseAddUnit,
      },
    ],
  },
  {
    path: '/unit/:unit',
    label: t({
      id: 'routes.label.unit',
      message: 'Onderdeel',
    }),
    component: UnitViewer,
    layout: FocusLayout,
  },
  {
    path: '/unit/:unit/:certification',
    label: t({
      id: 'routes.label.unit',
      message: 'Onderdeel',
    }),
    component: UnitViewer,
    layout: FocusLayout,
  },
]

export const routes = (): Route[] => {
  return [
    {
      path: '/',
      label: t({
        id: 'routes.label.dashboard',
        message: 'Home',
      }),
      component: Dashboard,
      container: false,
      permission: {
        action: PermissionAction.READ,
        subject: PermissionObjectType.DASHBOARD,
      },
      routes: [
        {
          path: '/remote',
          label: 'Event viewer',
          component: EventRemoteViewer,
        },
        {
          path: '/join',
          label: t({
            id: 'routes.label.join',
            message: 'Deelnemen aan groepsessie',
          }),
          component: JoinEventPage,
        },
        {
          path: '/join/:id',
          label: t({
            id: 'routes.label.join',
            message: 'Deelnemen aan groepsessie',
          }),
          component: JoinEventPage,
        },
        {
          path: '/profile',
          label: t({
            id: 'routes.label.profile',
            message: 'Mijn profiel',
          }),
          description: t({
            id: 'routes.description.profile',
            message: 'Pas hier je profiel aan',
          }),
          component: Profile,
        },
        {
          path: '/events',
          label: t({
            id: 'routes.label.events',
            message: 'Evenementen',
          }),
          component: EventOverview,
          container: true,
          permission: {
            action: PermissionAction.CREATE,
            subject: PermissionObjectType.EVENT,
          },
        },
        {
          path: '/certificates/:section',
          label: t({
            id: 'routes.label.certificates',
            message: 'Mijn attesten',
          }),
          component: Certificates,
          container: false,
          permission: {
            action: PermissionAction.READ,
            subject: PermissionObjectType.OWN_REPORT,
          },
        },
        {
          path: '/certificates',
          label: t({
            id: 'routes.label.certificates',
            message: 'Mijn attesten',
          }),
          component: Certificates,
          container: false,
          permission: {
            action: PermissionAction.READ,
            subject: PermissionObjectType.OWN_REPORT,
          },
        },
        {
          path: '/unit/edit/:unit',
          label: t({
            id: 'routes.label.edit-unit',
            message: 'Onderdeel bewerken',
          }),
          component: EditUnit,
        },
        {
          path: '/courses',
          label: t({
            id: 'routes.label.courses',
            message: 'Opleidingen',
          }),
          component: Courses,
          routes: [
            {
              path: '/add',
              label: t({
                id: 'routes.label.add-course',
                message: 'Opleiding aanmaken',
              }),
              component: CourseEdit,
              permission: {
                action: PermissionAction.CREATE,
                subject: PermissionObjectType.COURSE,
              },
            },
            {
              path: '/import',
              label: t({
                id: 'routes.label.import-courses',
                message: 'Opleidingen importeren',
              }),
              component: ImportCourses,
              permission: {
                or: [
                  {
                    action: PermissionAction.IMPORT,
                    subject: PermissionObjectType.COURSE,
                  },
                  {
                    action: PermissionAction.IMPORT,
                    subject: PermissionObjectType.BRANCH_COURSE,
                  },
                ],
              },
            },
            ...courses(),
          ],
        },
        {
          path: '/events/:event_id',
          label: t({
            id: 'routes.label.event',
            message: 'Evenement',
          }),
          component: EventHostViewer,
          layout: FocusLayout,
          permission: {
            action: PermissionAction.CREATE,
            subject: PermissionObjectType.EVENT,
          },
          routes: [
            { path: 'unit/:unit', component: UnitViewer, layout: FocusLayout },
          ],
        },
        {
          path: '/articles',
          label: t({
            id: 'routes.label.articles',
            message: 'Nieuws',
          }),
          component: ArticleOverview,
          routes: [
            {
              path: 'new',
              label: t({
                id: 'routes.label.new-article',
                message: 'Bericht aanmaken',
              }),
              component: ArticleEdit,
              permission: {
                action: PermissionAction.CREATE,
                subject: PermissionObjectType.ARTICLE,
              },
            },
            {
              path: ':slug',
              label: t({
                id: 'routes.label.article',
                message: 'Bericht',
              }),
              component: ArticlePage,
              container: false,
            },
            {
              path: ':id',
              label: t({
                id: 'routes.label.article',
                message: 'Bericht',
              }),
              component: ArticlePage,
              container: false,
              routes: [
                {
                  path: 'edit',
                  label: t({
                    id: 'routes.label.edit-article',
                    message: 'Bericht bewerken',
                  }),
                  component: ArticleEdit,
                },
              ],
            },
          ],
        },
        {
          path: '/reports',
          label: t({
            id: 'routes.label.reports',
            message: 'Rapporten',
          }),
          component: Reports,
          permission: {
            or: [
              {
                action: PermissionAction.READ,
                subject: PermissionObjectType.REPORT,
              },
              {
                action: PermissionAction.READ,
                subject: PermissionObjectType.OWN_REPORT,
              },
              {
                action: PermissionAction.READ,
                subject: PermissionObjectType.BRANCH_REPORT,
              },
            ],
          },
          routes: [
            {
              path: ':report',
              label: t({
                id: 'routes.label.reports',
                message: 'Rapporten',
              }),
              component: Reports,
              permission: {
                or: [
                  {
                    action: PermissionAction.READ,
                    subject: PermissionObjectType.REPORT,
                  },
                  {
                    action: PermissionAction.READ,
                    subject: PermissionObjectType.OWN_REPORT,
                  },
                  {
                    action: PermissionAction.READ,
                    subject: PermissionObjectType.BRANCH_REPORT,
                  },
                ],
              },
            },
          ],
        },
        {
          path: '/branch/:section',
          label: t({
            id: 'routes.label.branch',
            message: 'Kantoor',
          }),
          container: false,
          component: Branch,
          permission: {
            or: [
              {
                action: PermissionAction.READ,
                subject: PermissionObjectType.BRANCH_USER,
              },
              {
                action: PermissionAction.UPDATE,
                subject: PermissionObjectType.BRANCH_USER_GROUP,
              },
              {
                action: PermissionAction.UPDATE,
                subject: PermissionObjectType.BRANCH_COURSE_CATEGORY,
              },
              {
                action: PermissionAction.UPDATE,
                subject: PermissionObjectType.BRANCH_ROLE,
              },
              {
                action: PermissionAction.UPDATE,
                subject: PermissionObjectType.BRANCH_CERTIFICATION_TYPE,
              },
              {
                action: PermissionAction.UPDATE,
                subject: PermissionObjectType.BRANCH_JOB_OPPORTUNITY,
              },
            ],
          },
          routes: [
            {
              path: '/edit-role/:id',
              label: t({
                id: 'routes.label.edit-role',
                message: 'Rol bewerken',
              }),
              component: BranchRoleDetail,
              permission: {
                action: PermissionAction.UPDATE,
                subject: PermissionObjectType.BRANCH_ROLE,
              },
            },
            {
              path: '/edit-rule',
              label: t({
                id: 'routes.label.create-rule',
                message: 'Regel aanmaken',
              }),
              component: BranchRuleDetail,
              permission: {
                action: PermissionAction.CREATE,
                subject: PermissionObjectType.BRANCH_RULE,
              },
            },
            {
              path: '/edit-rule/:id',
              label: t({
                id: 'routes.label.edit-rule',
                message: 'Regel bewerken',
              }),
              component: BranchRuleDetail,
              permission: {
                action: PermissionAction.CREATE,
                subject: PermissionObjectType.BRANCH_RULE,
              },
            },
            {
              path: '/rule/:id',
              label: t({
                id: 'routes.label.view-rule',
                message: 'Regel bekijken',
              }),
              component: BranchRuleViewer,
              permission: {
                action: PermissionAction.READ,
                subject: PermissionObjectType.BRANCH_RULE,
              },
            },
          ],
        },
        {
          path: '/branch',
          label: t({
            id: 'routes.label.branch',
            message: 'Kantoor',
          }),
          container: false,
          component: Branch,
          permission: {
            or: [
              {
                action: PermissionAction.UPDATE,
                subject: PermissionObjectType.BRANCH_USER,
              },
              {
                action: PermissionAction.UPDATE,
                subject: PermissionObjectType.BRANCH_USER_GROUP,
              },
              {
                action: PermissionAction.UPDATE,
                subject: PermissionObjectType.BRANCH_COURSE_CATEGORY,
              },
              {
                action: PermissionAction.READ,
                subject: PermissionObjectType.BRANCH_REPORT,
              },
              {
                action: PermissionAction.READ,
                subject: PermissionObjectType.BRANCH_CERTIFICATION_TYPE,
              },
              {
                action: PermissionAction.READ,
                subject: PermissionObjectType.BRANCH_ROLE,
              },
              {
                action: PermissionAction.UPDATE,
                subject: PermissionObjectType.BRANCH_JOB_OPPORTUNITY,
              },
            ],
          },
        },
        {
          path: '/jobs',
          label: t({
            id: 'routes.label.jobs',
            message: 'Vacatures',
          }),
          component: JobOverview,
          permission: {
            action: PermissionAction.READ,
            subject: PermissionObjectType.JOB_OPPORTUNITY,
          },
          routes: [
            {
              path: ':job_id',
              label: t({
                id: 'routes.label.job',
                message: 'Vacature',
              }),
              component: JobViewer,
              permission: {
                or: [
                  {
                    action: PermissionAction.READ,
                    subject: PermissionObjectType.BRANCH_JOB_OPPORTUNITY,
                  },
                  {
                    action: PermissionAction.READ,
                    subject: PermissionObjectType.JOB_OPPORTUNITY,
                  },
                ],
              },
            },
          ],
        },
        {
          path: '/branch/jobs/:job_id',
          label: t({
            id: 'routes.label.job',
            message: 'Vacature',
          }),
          component: JobViewer,
          permission: {
            or: [
              {
                action: PermissionAction.READ,
                subject: PermissionObjectType.BRANCH_JOB_OPPORTUNITY,
              },
              {
                action: PermissionAction.READ,
                subject: PermissionObjectType.JOB_OPPORTUNITY,
              },
            ],
          },
        },
        {
          path: '/settings/:setting',
          label: t({
            id: 'routes.label.settings',
            message: 'Instellingen',
          }),
          component: GeneralSettings,
          permission: {
            action: PermissionAction.ACCESS,
            subject: PermissionObjectType.PLATFORM_SETTINGS,
          },
          routes: [
            {
              path: 'edit-role/:id',
              label: t({
                id: 'routes.label.edit-role',
                message: 'Rol bewerken',
              }),
              component: RoleDetail,
              permission: {
                action: PermissionAction.UPDATE,
                subject: PermissionObjectType.ROLE,
              },
            },
            {
              path: '/edit-rule/:id',
              label: t({
                id: 'routes.label.edit-rule',
                message: 'Regel bewerken',
              }),
              component: RuleDetail,
              permission: {
                action: PermissionAction.UPDATE,
                subject: PermissionObjectType.RULE,
              },
            },
            {
              path: '/edit-rule',
              label: t({
                id: 'routes.label.create-rule',
                message: 'Regel aanmaken',
              }),
              component: RuleDetail,
              permission: {
                action: PermissionAction.CREATE,
                subject: PermissionObjectType.RULE,
              },
            },
          ],
        },
        {
          path: '/settings',
          label: t({
            id: 'routes.label.settings',
            message: 'Instellingen',
          }),
          redirect: '/settings/general',
          routes: [
            {
              path: 'import-users',
              label: t({
                id: 'routes.label.import-users',
                message: 'Gebruikers importeren',
              }),
              component: ImportUsers,
              permission: {
                action: PermissionAction.IMPORT,
                subject: PermissionObjectType.USER,
              },
            },
            {
              path: 'import-branch-users',
              label: t({
                id: 'routes.label.import-branch-users',
                message: 'Gebruikers importeren',
              }),
              component: ImportBranchUsers,
              permission: {
                action: PermissionAction.IMPORT,
                subject: PermissionObjectType.BRANCH_USER,
              },
            },
            {
              path: 'export-users',
              label: t({
                id: 'routes.label.export-users',
                message: 'Gebruikers exporteren',
              }),
              component: ExportUsers,
              permission: {
                action: PermissionAction.EXPORT,
                subject: PermissionObjectType.USER,
              },
            },
          ],
        },
        {
          path: '/settings/branches/import',
          label: t({
            id: 'routes.label.import-branches',
            message: 'Afdelingen importeren',
          }),
          component: ImportBranches,
          permission: {
            action: PermissionAction.IMPORT,
            subject: PermissionObjectType.BRANCH,
          },
        },
        {
          path: '/settings/categories/translate/:id/:lang',
          label: t({
            id: 'routes.label.category-translation',
            message: 'Categorie vertalen',
          }),
          component: CategoryTranslation,
          permission: {
            action: PermissionAction.TRANSLATE,
            subject: PermissionObjectType.COURSE_CATEGORY,
          },
        },
        {
          path: '/articles/translate/:id/:lang',
          label: t({
            id: 'routes.label.article-translation',
            message: 'Article vertalen',
          }),
          component: ArticleTranslation,
          permission: {
            action: PermissionAction.TRANSLATE,
            subject: PermissionObjectType.ARTICLE,
          },
        },
        {
          path: '/settings/types/translate/:id/:lang',
          label: t({
            id: 'routes.label.course-type-translation',
            message: 'Opleidingstype vertalen',
          }),
          component: CourseTypeTranslation,
          permission: {
            action: PermissionAction.TRANSLATE,
            subject: PermissionObjectType.COURSE_TYPE,
          },
        },
        {
          path: '/settings/fields/translate/:id/:lang',
          label: t({
            id: 'routes.label.field-translation',
            message: 'Veld vertalen',
          }),
          component: FieldTranslation,
          permission: {
            action: PermissionAction.TRANSLATE,
            subject: PermissionObjectType.CUSTOM_FIELD,
          },
        },
        {
          path: '/settings/courses/translate/:id/:lang',
          label: t({
            id: 'routes.label.course-translation',
            message: 'Opleiding vertalen',
          }),
          component: CourseTranslation,
          permission: {
            action: PermissionAction.TRANSLATE,
            subject: PermissionObjectType.COURSE,
          },
        },
        {
          path: '/likes',
          label: t({
            id: 'routes.label.likes',
            message: 'Mijn lijst',
          }),
          component: Likes,
          permission: {
            action: PermissionAction.READ,
            subject: PermissionObjectType.LIKE,
          },
        },
        {
          path: '/assigned',
          label: t({
            id: 'routes.label.assigned',
            message: 'Aan mij toegewezen',
          }),
          component: Assigned,
          permission: {
            action: PermissionAction.READ,
            subject: PermissionObjectType.OWN_REPORT,
          },
        },
      ],
    },
    {
      path: '/x/:key',
      label: t({
        id: 'routes.label.loading',
        message: 'Loading...',
      }),
      component: Extension,
      container: false,
      footer: false,
      routes: [...courses()],
    },
    {
      path: '/welcome',
      label: t({
        id: 'routes.label.welcome',
        message: 'Welkom',
      }),
      layout: FocusLayout,
      anonOnly: true,
      component: Welcome,
    },
    {
      path: '/login',
      label: t({
        id: 'routes.label.login',
        message: 'Inloggen',
      }),
      anonOnly: true,
      component: Login,
    },
    {
      path: '/adminlogin',
      label: t({
        id: 'routes.label.login',
        message: 'Inloggen',
      }),
      anonOnly: true,
      component: Login,
    },
    {
      path: '/logout',
      label: t({
        id: 'routes.label.logout',
        message: 'Uitloggen',
      }),
      component: Logout,
    },
    {
      path: '/reset-password',
      label: t({
        id: 'routes.label.reset-password',
        message: 'Wachtwoord resetten',
      }),
      anonOnly: true,
      component: ResetPassword,
    },
    {
      path: '/auth',
      label: 'redirecting',
      anonOnly: true,
      component: TokenHandler,
    },
    {
      path: '/auth',
      label: 'redirecting',
      anonOnly: true,
      redirect: '/login',
    },
    {
      path: '/language-selector',
      label: 'Language selection',
      anonOnly: true,
      component: LanguageSelector,
      layout: FocusLayout,
    },
    // {
    //   path: '/register',
    //   label: t({
    //     id: 'routes.label.register',
    //     message: 'Registreren',
    //   }),
    //   anonOnly: true,
    //   component: Register,
    // },
  ]
}
