import {
  CaretRightFilled,
  DeleteFilled,
  CheckOutlined,
} from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { Trans } from '@lingui/macro'
import { Button, Card, Col, Row, Space, Tooltip } from 'antd'
import { useNavigate } from 'react-router-dom'

import {
  CancelEventMutation,
  EventStatus,
  FinishEventMutation,
  MyHostedEventsQuery,
} from 'apps/lms-front/src/generated/graphql'

import { LoadScreen } from '../../../core/components/LoadScreen'
import CANCEL_EVENT_MUTATION from '../../mutations/cancel-event.graphql'
import FINISH_EVENT_MUTATION from '../../mutations/finish-event.graphql'
import MY_HOSTED_EVENTS_QUERY from '../../queries/my-hosted-events.graphql'

export const EventOverview = () => {
  const { data: activeEvents, loading } = useQuery<MyHostedEventsQuery>(
    MY_HOSTED_EVENTS_QUERY
  )
  const [cancelEvent] = useMutation<CancelEventMutation>(
    CANCEL_EVENT_MUTATION,
    {
      refetchQueries: ['myHostedEvents'],
    }
  )
  const [finishEvent] = useMutation<FinishEventMutation>(
    FINISH_EVENT_MUTATION,
    {
      refetchQueries: ['myHostedEvents'],
    }
  )
  const navigate = useNavigate()

  if (loading) {
    return <LoadScreen />
  }

  return (
    <Row gutter={[15, 15]}>
      <Col sm={{ span: 12 }}>
        <Card>
          <h2>
            <Trans id="events.group.subtitle.active">
              Actieve groepsessies
            </Trans>
          </h2>
          <ul>
            {activeEvents?.myHostedEvents
              .filter(
                (event) =>
                  event.status === EventStatus.Scheduled ||
                  event.status === EventStatus.Started
              )
              .map((event) => (
                <li key={event._id}>
                  <Space>
                    {event.course?.name}
                    <Tooltip title="Evenement starten">
                      <Button
                        type="link"
                        style={{ padding: 0, color: 'var(--text-color)' }}
                        onClick={() => navigate(`/events/${event._id}`)}
                      >
                        <CaretRightFilled />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Evenement verwijderen">
                      <Button
                        type="link"
                        style={{ padding: 0, color: 'var(--text-color)' }}
                        onClick={() =>
                          cancelEvent({ variables: { id: event._id } })
                        }
                      >
                        <DeleteFilled />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Evenement afronden">
                      <Button
                        type="link"
                        style={{ padding: 0, color: 'var(--text-color)' }}
                        onClick={() =>
                          finishEvent({ variables: { id: event._id } })
                        }
                      >
                        <CheckOutlined />
                      </Button>
                    </Tooltip>
                  </Space>
                </li>
              ))}
          </ul>
        </Card>
      </Col>
      <Col sm={{ span: 12 }}>
        <Card>
          <h2>
            <Trans id="events.group.subtitle.completed">
              Voltooide groepsessies
            </Trans>
          </h2>
          <ul>
            {activeEvents?.myHostedEvents
              .filter((event) => event.status === EventStatus.Finished)
              .map((event) => (
                <li key={event._id}>
                  <Space>{event.course?.name}</Space>
                </li>
              ))}
          </ul>
        </Card>
      </Col>
    </Row>
  )
}
