import { Trans } from '@lingui/macro'
import { ConfigProvider } from 'antd'
import Color from 'color'
import { Helmet } from 'react-helmet-async'
import { load } from 'webfontloader'

import { BranchQuery } from 'apps/lms-front/src/generated/graphql'

import { BranchContext } from '../context/branch.context'
import { HostGuard } from '../guards/host.guard'
import { cleanHostname } from '../utils/branch'

export function BranchProvider({
  children,
  branch,
  loading,
  hostname,
  ssr = false,
}: {
  branch: BranchQuery['branch']
  children: React.ReactNode
  loading: boolean
  hostname: string
  ssr?: boolean
}) {
  if (loading) return null
  if (branch) {
    if (
      !(
        branch.hostnames.includes(cleanHostname(hostname)) ||
        branch.hostnames.includes('*')
      )
    ) {
      if (branch.redirects?.includes(cleanHostname(hostname)))
        window.location.href = `https://${branch.hostnames[0]}`
      else
        return (
          <div style={{ padding: '2rem', textAlign: 'center' }}>
            <Trans id="error.domain_not_authorized">
              Domein niet toegestaan.
            </Trans>
          </div>
        )
    }

    /* Set Ant Design theme values */
    ConfigProvider.config({
      theme: {
        primaryColor: branch.theme.primaryColor || undefined,
      },
    })

    const root = document.documentElement
    root?.style.setProperty('--primary-color', branch?.theme.primaryColor)

    if (root && branch?.theme.textColor) {
      const primaryColorLuminosity = Color(
        branch?.theme.primaryColor
      ).luminosity()

      root.style.setProperty('--text-color', branch?.theme.textColor)
      root.style.setProperty(
        '--btn-text-color',
        primaryColorLuminosity >= 0.45 ? branch?.theme.textColor : '#FFF'
      )

      if (
        (primaryColorLuminosity >= 0.35 && primaryColorLuminosity < 0.45) ||
        (primaryColorLuminosity >= 0.75 && primaryColorLuminosity <= 1)
      ) {
        root.style.setProperty('--btn-font-weight', '500')
      }
    }

    if (root && branch?.theme.zoom) {
      root.style.setProperty('zoom', branch?.theme.zoom * 100 + '%')
    }

    if (root && branch?.theme.headerColor) {
      root.style.setProperty('--header-color', branch?.theme.headerColor)
    }

    if (root && branch?.theme.likeColor) {
      root.style.setProperty('--like-color', branch?.theme.likeColor)
    }

    if (root && branch?.theme.secondaryColor) {
      root.style.setProperty(
        '--secondary-color',
        branch?.theme.secondaryColor || branch?.theme.headerColor
      )
    } else if (root && branch?.theme.headerColor) {
      root.style.setProperty('--secondary-color', branch?.theme.headerColor)
    }

    /* Set fontFamily */
    const body = document.querySelector('body')
    if (body && branch?.theme.fontFamily) {
      body.style.fontFamily = `${branch.theme.fontFamily}, sans-serif`
      if (
        branch?.theme.fontFamily !== 'Albert Sans' &&
        branch?.theme.fontFamily !== 'Ovo' &&
        !ssr
      )
        load({
          google: {
            families: [`${branch.theme.fontFamily}:300,400,500,700,400i`],
          },
        })
    }
  }

  return (
    <BranchContext.Provider value={branch}>
      {branch.theme.customCss && (
        <Helmet>
          <style>{branch?.theme.customCss}</style>
        </Helmet>
      )}
      <HostGuard>{children}</HostGuard>
    </BranchContext.Provider>
  )
}
