// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable sonarjs/no-identical-functions */

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  Button,
  Col,
  PageHeader,
  Row,
  Tag,
  Form,
  Input,
  Divider,
  InputNumber,
  notification,
  Upload,
  Select,
  Switch,
  Checkbox,
  CheckboxOptionType,
  Alert,
  TreeSelect,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Store } from 'antd/lib/form/interface'
import { UploadChangeParam } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import { ObjectId } from 'bson'
import dayjs from 'dayjs'
import { debounce } from 'lodash-es'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import slugify from 'slugify'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  CheckCourseSlugQuery,
  CourseExtrasQuery,
  CourseQuery,
  CourseTagsQuery,
  CreateCourseInput,
  CreateCourseMutation,
  FieldParent,
  FieldsQuery,
  FieldType,
  UpdateCourseInput,
  UpdateCourseMutation,
} from 'apps/lms-front/src/generated/graphql'
import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'
import { useBranch } from 'apps/lms-front/src/modules/auth/hooks/use-branch'
import { Content } from 'apps/lms-front/src/modules/shared/layout/Layout.style'

import { AbilityContext, Can } from '../../../auth/components/Can'
import { LoadScreen } from '../../../core/components/LoadScreen'
import { getParentRoute } from '../../../core/routes/router'
import { CharacterLimitHelper } from '../../../shared/components/character-limit-helper/CharacterLimitHelper'
import DatePicker from '../../../shared/components/date-picker/DatePicker'
import { RichEditor } from '../../../shared/components/rich-editor/RichEditor'
import { errorNotifierFn } from '../../../shared/helpers/error-notifier'
import { PageProps } from '../../../shared/interfaces/page.interface'
import { getBase64 } from '../../../shared/utils/get-base64'
import {
  FileType,
  uploadValidator,
} from '../../../shared/validators/upload-file-validator'

import FIELDS_QUERY from './../../../settings/queries/fields.graphql'
import CREATE_COURSE_MUTATION from './../../mutations/create-course.graphql'
import UPDATE_COURSE_MUTATION from './../../mutations/update-course.graphql'
import CHECK_SLUG from './../../queries/check-course-slug.graphql'
import COURSE_EXTRAS_QUERY from './../../queries/course-extras.graphql'
import COURSE_TAGS_QUERY from './../../queries/course-tags.graphql'
import COURSE_QUERY from './../../queries/course.graphql'

export const CourseEdit = ({ route }: PageProps) => {
  const params = useParams()
  const { id } = params
  const parent = getParentRoute(route, params)
  const navigate = useNavigate()
  const branch = useBranch()
  const ability = useContext(AbilityContext)

  const { user } = useAuth()

  const [form] = useForm()
  const intro = Form.useWatch('short_description', form)
  const description = Form.useWatch('description', form)

  const [formDirty, setFormDirty] = useState<boolean>(false)
  const [uploading, setUploading] = useState<boolean>(false)

  const [fetchCourseData, { data: course, refetch }] =
    useLazyQuery<CourseQuery>(COURSE_QUERY, {
      variables: { id },
      fetchPolicy: 'network-only',
    })

  const { data: courseTags } = useQuery<CourseTagsQuery>(COURSE_TAGS_QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  const { data: courseExtras } =
    useQuery<CourseExtrasQuery>(COURSE_EXTRAS_QUERY)

  const [checkSlug] = useLazyQuery<CheckCourseSlugQuery>(CHECK_SLUG, {
    fetchPolicy: 'network-only',
  })

  const { data: fields } = useQuery<FieldsQuery>(FIELDS_QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    id && fetchCourseData()
  }, [id, fetchCourseData])

  const [updateCourse, { loading: updating }] =
    useMutation<UpdateCourseMutation>(UPDATE_COURSE_MUTATION)
  const [createCourse, { loading: creating }] =
    useMutation<CreateCourseMutation>(CREATE_COURSE_MUTATION)

  const client = useApolloClient()

  const handleChange = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setUploading(true)
      return
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, () => {
        setUploading(false)
        client.refetchQueries({ include: ['courses', 'course'] })
      })
    }
  }

  const uploadButton = (
    <div>
      {uploading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>
        {uploading ? (
          <Trans id="action.uploading">Uploading</Trans>
        ) : (
          <Trans id="action.upload">Upload</Trans>
        )}
      </div>
    </div>
  )

  const MAX_CHARACTERS = {
    CODE: 20,
    NAME: 100,
    SLUG: 100,
    INTRO: 500,
    LECTURER: 50,
    CONTENT: 5000,
  }

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={route.label}
        subTitle={route.description}
        tags={
          course?.fetchCourseById ? (
            course?.fetchCourseById.published ? (
              <Tag color="blue">
                <Trans id="course.tag.published">Gepubliceerd</Trans>
              </Tag>
            ) : (
              <Tag color="orange">
                <Trans id="course.tag.draft">Concept</Trans>
              </Tag>
            )
          ) : (
            <></>
          )
        }
        extra={[
          <Button onClick={() => navigate(parent)} key="2">
            <Trans id="courses.course_edit.go_back">Ga terug</Trans>
          </Button>,
          <Button
            disabled={!formDirty}
            loading={creating || updating}
            onClick={() => form.submit()}
            key="1"
            type="primary"
          >
            <Trans id="action.save">Opslaan</Trans>
          </Button>,
        ]}
      />
      <Content>
        <Row justify="center" style={{ flex: 1 }}>
          <Col xs={24}>
            {user?.roles.some((role) =>
              new ObjectId('641f58173d41980385df442a').equals(
                role?.role_id || ''
              )
            ) && (
              <Alert
                type="warning"
                showIcon={true}
                message={
                  <Trans id="courses.course_edit.warning.software_academy">
                    <p>
                      <strong>
                        Beste partner, gelieve rekening te willen houden met
                        volgende zaken bij het creëren van een opleiding.
                      </strong>
                    </p>
                    <p>
                      De opleiding dient steeds informatief & educatief te zijn,
                      volgende zaken worden niet toegelaten: Aftermovie&apos;s,
                      getuigenissen, promotionele actie&apos;s,
                      tarieven/prijzen, verwijzingen naar andere partijen /
                      concurrenten. Opleidingen met een commerciële inhoud
                      worden niet toegelaten en zullen worden verwijderd.
                    </p>
                    <p>Bedankt voor jullie begrip.</p>
                  </Trans>
                }
                style={{ marginTop: -24, marginBottom: 24 }}
              />
            )}
            {!id || course ? (
              <Form
                form={form}
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={
                  id
                    ? {
                        ...(course?.fetchCourseById as Store),
                        category: course?.fetchCourseById.category?.map(
                          (category) => category._id
                        ),
                        tags: course?.fetchCourseById.tags?.map(
                          (tag) => tag.name
                        ),
                        certificationType:
                          course?.fetchCourseById.certificationType?.map(
                            (type) => type._id
                          ),
                        meta: undefined,
                      }
                    : undefined
                }
                onFinish={(
                  variables: CreateCourseInput | UpdateCourseInput
                ) => {
                  if (id) {
                    updateCourse({
                      variables: {
                        id,
                        ...variables,
                        tags: variables.tags?.map(
                          (tag: string) =>
                            courseTags?.fetchCourseTags.find(
                              (t) => t.name === tag
                            )?._id || tag
                        ),
                        published: !!variables.published,
                      },
                      update(cache) {
                        cache.evict({
                          id: 'ROOT_QUERY',
                          fieldName: 'fetchCourses',
                        })
                        cache.gc()
                      },
                    })
                      .then(() => {
                        refetch()
                        notification.success({
                          message: t({
                            id: 'courses.course_edit.success',
                            message: 'Opleiding succesvol opgeslagen.',
                          }),
                        })
                        navigate(parent)
                      })
                      .catch(errorNotifierFn)
                  } else {
                    createCourse({
                      variables: {
                        ...variables,
                        tags: variables.tags?.map(
                          (tag: string) =>
                            courseTags?.fetchCourseTags.find(
                              (t) => t.name === tag
                            )?._id || tag
                        ),
                      },
                      update(cache) {
                        cache.evict({
                          id: 'ROOT_QUERY',
                          fieldName: 'fetchCourses',
                        })
                        cache.gc()
                      },
                    })
                      .then((result) => {
                        notification.success({
                          message: t({
                            id: 'courses.course_edit.success',
                            message: 'Opleiding succesvol opgeslagen.',
                          }),
                        })
                        const id = result.data?.createCourse._id
                        return navigate(`${parent}/${id}`, { replace: true })
                      })
                      .catch(errorNotifierFn)
                  }
                }}
                onFieldsChange={() => setFormDirty(true)}
                autoComplete="off"
              >
                {id && (
                  <Form.Item wrapperCol={{ sm: { offset: 8, span: 16 } }}>
                    <Upload
                      name="file"
                      listType="picture-card"
                      className="course-image-uploader"
                      showUploadList={false}
                      action={`${
                        import.meta.env.NX_BACKEND_URL
                      }/api/files/uploadCourseImage/${id}`}
                      beforeUpload={uploadValidator(3, [
                        FileType.jpg,
                        FileType.png,
                        FileType.gif,
                      ])}
                      onChange={handleChange}
                      headers={{
                        Authorization: `Bearer ${localStorage.getItem(
                          'aa_lms_at'
                        )}`,
                        'x-academy-host': window.location.hostname,
                      }}
                    >
                      {course?.fetchCourseById.image?.url && !uploading ? (
                        <img
                          src={course?.fetchCourseById.image?.url}
                          alt={course?.fetchCourseById.image?.alt || ''}
                          style={{
                            width: '100%',
                          }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </Form.Item>
                )}
                <Form.Item
                  label={t({
                    id: 'courses.course_edit.form.label.name',
                    message: 'Naam',
                  })}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t({
                        id: 'courses.course_edit.form.validation.name',
                        message:
                          'Gelieve een naam voor deze opleiding in te vullen',
                      }),
                    },
                    {
                      max: MAX_CHARACTERS.NAME,
                      message: t({
                        id: 'courses.course_edit.form.validation.max_characters',
                        message: `Gelieve onder de ${MAX_CHARACTERS.NAME} tekens te blijven`,
                      }),
                    },
                  ]}
                >
                  <Input
                    onChange={debounce(async (e) => {
                      const slug = slugify(e.target.value, {
                        lower: true,
                        remove: /[!"'()*+,.:?@~]/g,
                      })
                      const { data } = await checkSlug({
                        variables: {
                          slug,
                        },
                      })
                      form.setFieldsValue({
                        slug: data?.checkCourseSlug || slug,
                      })
                    }, 250)}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <Trans id="courses.course_edit.form.label.slug">
                      URL-vriendelijke naam
                    </Trans>
                  }
                  name="slug"
                  rules={[
                    {
                      required: true,
                      message: t({
                        id: 'courses.course_edit.form.validation.slug',
                        message:
                          'Gelieve een URL-vriendelijke naam voor deze opleiding in te vullen',
                      }),
                    },
                    {
                      max: MAX_CHARACTERS.SLUG,
                      message: t({
                        id: 'courses.course_edit.form.validation.max_characters',
                        message: `Gelieve onder de ${MAX_CHARACTERS.SLUG} tekens te blijven`,
                      }),
                    },
                  ]}
                >
                  <Input
                    onBlur={async (e) => {
                      const slug = slugify(e.target.value, {
                        lower: true,
                        remove: /[!"'()*+,.:?@~]/g,
                      })
                      const { data } = await checkSlug({
                        variables: {
                          slug,
                        },
                      })
                      form.setFieldsValue({
                        slug: data?.checkCourseSlug || slug,
                      })
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label={t({
                    id: 'courses.course_edit.form.label.intro',
                    message: 'Korte beschrijving',
                  })}
                  name="short_description"
                  help={
                    <CharacterLimitHelper
                      content={intro}
                      max={MAX_CHARACTERS.INTRO}
                    />
                  }
                  rules={[
                    {
                      max: MAX_CHARACTERS.INTRO,
                      message: t({
                        id: 'courses.course_edit.form.validation.max_characters',
                        message: `Gelieve onder de ${MAX_CHARACTERS.INTRO} tekens te blijven`,
                      }),
                    },
                  ]}
                >
                  <Input.TextArea rows={4} />
                </Form.Item>
                <Form.Item
                  label={t({
                    id: 'courses.course_edit.form.label.description',
                    message: 'Beschrijving',
                  })}
                  name="description"
                  help={
                    <CharacterLimitHelper
                      content={description}
                      max={MAX_CHARACTERS.CONTENT}
                    />
                  }
                  rules={[
                    {
                      max: MAX_CHARACTERS.CONTENT,
                      message: t({
                        id: 'courses.course_edit.form.validation.max_characters',
                        message: `Gelieve onder de ${MAX_CHARACTERS.CONTENT} tekens te blijven`,
                      }),
                    },
                  ]}
                >
                  <RichEditor disableTextStyles />
                </Form.Item>
                <Divider plain />
                <Form.Item
                  label={t({
                    id: 'courses.course_edit.form.label.code',
                    message: 'Code',
                  })}
                  name="code"
                  rules={[
                    {
                      max: MAX_CHARACTERS.CODE,
                      message: t({
                        id: 'courses.course_edit.form.validation.max_characters',
                        message: `Gelieve onder de ${MAX_CHARACTERS.CODE} tekens te blijven`,
                      }),
                    },
                  ]}
                >
                  <Input
                    placeholder={t({
                      id: 'courses.course_edit.form.placeholder.code',
                      message: 'OPLEIDING001',
                    })}
                  />
                </Form.Item>
                <Form.Item
                  label={t({
                    id: 'courses.course_edit.form.label.lecturer',
                    message: 'Docent',
                  })}
                  name="lecturer"
                  rules={[
                    {
                      max: MAX_CHARACTERS.LECTURER,
                      message: t({
                        id: 'courses.course_edit.form.validation.max_characters',
                        message: `Gelieve onder de ${MAX_CHARACTERS.LECTURER} tekens te blijven`,
                      }),
                    },
                  ]}
                >
                  <Input
                    placeholder={t({
                      id: 'courses.course_edit.form.placeholder.lecturer',
                      message: 'John Doe',
                    })}
                  />
                </Form.Item>
                <Form.Item
                  label={t({
                    id: 'courses.course_edit.form.label.duration',
                    message: 'Duur van de opleiding',
                  })}
                  name="duration"
                >
                  <InputNumber addonAfter="min" min={1} />
                </Form.Item>
                <Form.Item
                  label={t({
                    id: 'courses.course_edit.form.label.level',
                    message: 'Niveau',
                  })}
                  name="level"
                >
                  <Select style={{ width: 120 }}>
                    {[
                      {
                        label: t({
                          id: 'courses.course_edit.form.level.starter',
                          message: 'starter',
                        }),
                        value: 'Beginner',
                      },
                      {
                        label: t({
                          id: 'courses.course_edit.form.level.intermediate',
                          message: 'gevorderd',
                        }),
                        value: 'Advanced',
                      },
                      {
                        label: t({
                          id: 'courses.course_edit.form.level.expert',
                          message: 'expert',
                        }),
                        value: 'Expert',
                      },
                    ].map((level) => (
                      <Select.Option key={level.value} value={level.value}>
                        {level.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={'category'}
                  label={t({
                    id: 'courses.course_edit.form.label.category',
                    message: 'Categorie',
                  })}
                  hidden={
                    !(
                      courseExtras?.fetchBranchCategories.length ||
                      courseExtras?.fetchCategories.length
                    )
                  }
                >
                  <Select
                    mode="multiple"
                    allowClear
                    options={[
                      {
                        label: branch?.name || '',
                        options:
                          courseExtras?.fetchBranchCategories?.map(
                            (category) => ({
                              label: category.name,
                              value: category._id,
                            })
                          ) || [],
                      },
                      {
                        label: t({
                          id: 'courses.course_edit.form.default_categories',
                          message: 'Standaardcategorieën',
                        }),
                        options:
                          courseExtras?.fetchCategories?.map((category) => ({
                            label: category.name,
                            value: category._id,
                          })) || [],
                      },
                    ]}
                  />
                </Form.Item>
                <Can
                  I={PermissionAction.ASSIGN}
                  a={PermissionObjectType.BRANCH_COURSE_TAG}
                >
                  <Form.Item
                    name={'tags'}
                    label={t({
                      id: 'courses.course_edit.form.label.tags',
                      message: 'Tags',
                    })}
                  >
                    <Select
                      mode="tags"
                      allowClear
                      options={courseTags?.fetchCourseTags?.map((tag) => ({
                        label: tag.name,
                        value: tag.name,
                      }))}
                      filterOption={(input, option) =>
                        option?.label
                          .toLowerCase()
                          .includes(input.toLowerCase()) || false
                      }
                      notFoundContent={null}
                    />
                  </Form.Item>
                </Can>
                <Form.Item
                  label={t({
                    id: 'courses.course_edit.form.label.type',
                    message: 'Type',
                  })}
                  name="type"
                  hidden={!courseExtras?.fetchCourseTypes.length}
                >
                  <TreeSelect
                    treeDataSimpleMode
                    style={{ width: 240 }}
                    treeData={courseExtras?.fetchCourseTypes.map((type) => ({
                      label: type.name,
                      value: type._id,
                      id: type._id,
                      pId: type.parent_id,
                    }))}
                  />
                </Form.Item>
                {courseExtras?.fetchCertificationTypes?.length && (
                  <Can
                    I={PermissionAction.ASSIGN}
                    a={PermissionObjectType.CERTIFICATION_TYPE}
                  >
                    <Form.Item
                      label={t({
                        id: 'courses.course_edit.form.label.certification_types',
                        message: 'Certificeringstypes',
                      })}
                      name="certificationType"
                      help={
                        courseExtras.fetchCertificationTypes.some(
                          (type) =>
                            branch &&
                            new ObjectId(branch?._id).equals(
                              type.branch_id || ''
                            )
                        ) ? (
                          <p>
                            {t({
                              id: 'settings.users.form.help.certification_types',
                              message: '* op afdelingsniveau',
                            })}
                          </p>
                        ) : null
                      }
                    >
                      <Checkbox.Group
                        options={courseExtras.fetchCertificationTypes
                          .filter((type) =>
                            ability.can(
                              PermissionAction.ASSIGN,
                              PermissionObjectType.BRANCH_CERTIFICATION_TYPE
                            )
                              ? (type.branch_id &&
                                  new ObjectId(type.branch_id).equals(
                                    branch?._id
                                  )) ||
                                !type.branch_id
                              : !type.branch_id
                          )
                          .map(
                            (type) =>
                              ({
                                label: `${type.name}${
                                  type.branch_id ? ' *' : ''
                                }`,
                                value: type._id as string,
                              }) as CheckboxOptionType
                          )}
                      />
                    </Form.Item>
                  </Can>
                )}
                {courseExtras?.fetchBranchCertificationTypes &&
                courseExtras?.fetchBranchCertificationTypes?.length > 0 ? (
                  <Can
                    not
                    I={PermissionAction.ASSIGN}
                    a={PermissionObjectType.CERTIFICATION_TYPE}
                  >
                    <Can
                      I={PermissionAction.ASSIGN}
                      a={PermissionObjectType.BRANCH_CERTIFICATION_TYPE}
                    >
                      <Form.Item
                        label={t({
                          id: 'courses.course_edit.form.label.certification_types',
                          message: 'Certificeringstypes',
                        })}
                        name="certificationType"
                      >
                        <Checkbox.Group
                          options={courseExtras.fetchBranchCertificationTypes.map(
                            (type) =>
                              ({
                                label: type.name,
                                value: type._id as string,
                              }) as CheckboxOptionType
                          )}
                        />
                      </Form.Item>
                    </Can>
                  </Can>
                ) : null}
                {fields?.fetchFields
                  .filter((field) => field.parent === FieldParent.Course)
                  .map((field) => {
                    if (field.type === FieldType.TextLong)
                      return (
                        <Form.Item
                          key={field.key}
                          label={field.name}
                          name={['meta', field.key]}
                          initialValue={
                            course?.fetchCourseById.meta
                              ? course?.fetchCourseById.meta[field.key] ||
                                undefined
                              : undefined
                          }
                        >
                          <Input.TextArea cols={3} />
                        </Form.Item>
                      )
                    if (field.type === FieldType.Number)
                      return (
                        <Form.Item
                          key={field.key}
                          label={field.name}
                          name={['meta', field.key]}
                          initialValue={
                            course?.fetchCourseById.meta
                              ? course?.fetchCourseById.meta[field.key]
                                ? Number.parseInt(
                                    course?.fetchCourseById.meta[field.key]
                                  )
                                : undefined
                              : undefined
                          }
                        >
                          <InputNumber />
                        </Form.Item>
                      )
                    if (field.type === FieldType.Date)
                      return (
                        <Form.Item
                          key={field.key}
                          label={field.name}
                          name={['meta', field.key]}
                          initialValue={
                            course?.fetchCourseById.meta
                              ? course?.fetchCourseById.meta[field.key]
                                ? dayjs(course?.fetchCourseById.meta[field.key])
                                : undefined
                              : undefined
                          }
                        >
                          <DatePicker
                            allowClear={true}
                            format="DD/MM/YYYY"
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      )
                    return (
                      <Form.Item
                        key={field.key}
                        label={field.name}
                        name={['meta', field.key]}
                        initialValue={
                          course?.fetchCourseById.meta
                            ? course?.fetchCourseById.meta[field.key] ||
                              undefined
                            : undefined
                        }
                      >
                        <Input />
                      </Form.Item>
                    )
                  })}
                <Form.Item
                  label={t({
                    id: 'courses.course_edit.form.label.status',
                    message: 'Status',
                  })}
                  name="published"
                  valuePropName="checked"
                >
                  <Switch
                    unCheckedChildren={
                      <Trans id="course.tag.draft">Concept</Trans>
                    }
                    checkedChildren={
                      <Trans id="course.tag.published">Gepubliceerd</Trans>
                    }
                  />
                </Form.Item>
                <Form.Item wrapperCol={{ sm: { offset: 8, span: 16 } }}>
                  <Button
                    disabled={!formDirty}
                    loading={creating || updating}
                    type="primary"
                    htmlType={'submit'}
                  >
                    <Trans id="courses.course_edit.form.submit">
                      Opleiding opslaan
                    </Trans>
                  </Button>
                </Form.Item>
              </Form>
            ) : (
              <LoadScreen />
            )}
          </Col>
        </Row>
      </Content>
    </>
  )
}
