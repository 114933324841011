/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { Plural, Trans, t } from '@lingui/macro'
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Select,
  Space,
  Switch,
  TreeSelect,
} from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useContext, useEffect, useMemo } from 'react'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  AssignCourseMutationVariables,
  BranchAccessibleCoursesQuery,
  BranchUsersGroupsQuery,
  BranchUsersKeyValuesQuery,
  HierarchyQuery,
} from 'apps/lms-front/src/generated/graphql'

import { AbilityContext } from '../../../auth/components/Can'
import {
  TreeSelectNodeProps,
  prepSectionsForTreeSelect,
  prepUsersForTreeSelect,
} from '../../../branch/hooks/use-hierarchy-tree'
import { LoadSection } from '../../../core/components/LoadScreen'
import DatePicker from '../../../shared/components/date-picker/DatePicker'
import { errorNotifierFn } from '../../../shared/helpers/error-notifier'

import BRANCH_COURSES from './../../../branch/queries/branch-accessible-courses.graphql'
import HIERARCHY_QUERY from './../../../branch/queries/hierarchy.graphql'
import BRANCH_USERS_GROUPS from './../../queries/branch-users-groups.graphql'
import BRANCH_USERS_KEY_VALUES from './../../queries/branch-users-key-values.graphql'

export const CourseAssignmentModal = ({
  visible,
  onVisibilityChange,
  onAssign,
  onUpdate,
  assigning,
  subject,
  course,
  users,
}: {
  course?: string
  users?: string[]
  subject?: {
    mandatory: boolean
    message: string
    deadline?: Dayjs
    reminders?: {
      date: Dayjs
      time_amount: number
      time_unit: 'hours' | 'days' | 'weeks'
    }[]
  }
  visible: boolean
  assigning: boolean
  onVisibilityChange: (state: boolean) => void
  onAssign?: (data: AssignCourseMutationVariables) => Promise<unknown>
  onUpdate?: (data: Partial<AssignCourseMutationVariables>) => Promise<unknown>
}) => {
  const [_, contextHolder] = notification.useNotification()
  const ability = useContext(AbilityContext)

  const { data: groupsData, loading: groupsLoading } =
    useQuery<BranchUsersGroupsQuery>(BRANCH_USERS_GROUPS, {
      skip:
        ability.cannot(
          PermissionAction.READ,
          PermissionObjectType.BRANCH_USER_GROUP
        ) || !visible,
    })

  const { data: usersData, loading: usersLoading } =
    useQuery<BranchUsersKeyValuesQuery>(BRANCH_USERS_KEY_VALUES, {
      skip: !visible,
      fetchPolicy: 'cache-and-network',
    })
  const { data: courses, loading: coursesLoading } =
    useQuery<BranchAccessibleCoursesQuery>(BRANCH_COURSES, {
      skip: !visible,
      fetchPolicy: 'cache-and-network',
    })
  const { data: hierarchy, loading: hierarchyLoading } =
    useQuery<HierarchyQuery>(HIERARCHY_QUERY, {
      skip: !visible,
      fetchPolicy: 'network-only',
    })

  const treeData = useMemo(() => {
    const hierarchyNodes: TreeSelectNodeProps[] = hierarchy?.fetchHierarchy
      ? prepSectionsForTreeSelect(hierarchy?.fetchHierarchy, ability, {
          action: PermissionAction.CREATE,
          object: PermissionObjectType.ASSIGNMENT,
        })
          .map((node) => {
            return node.pId
              ? node
              : {
                  ...node,
                  disabled: false,
                  selectable: !node.disabled,
                }
          })
          .filter((node) => !node.disabled)
          .filter((node) => node.pId || node.selectable)
      : []

    const userNodes: TreeSelectNodeProps[] = usersData?.fetchBranchUsers
      ? prepUsersForTreeSelect(usersData.fetchBranchUsers, ability, {
          action: PermissionAction.CREATE,
          object: PermissionObjectType.ASSIGNMENT,
        }).filter((node) => !node.disabled)
      : []
    if (userNodes.length > 0)
      userNodes.unshift({
        id: 'users',
        selectable: false,
        value: 'users',
        title: t({
          id: 'modal.assign_courses.form.users.users',
          message: 'Gebruikers',
        }),
        label: t({
          id: 'modal.assign_courses.form.users.users',
          message: 'Gebruikers',
        }),
        level: 0,
      })

    const groupNodes: TreeSelectNodeProps[] =
      groupsData?.fetchBranchGroups.map((group) => ({
        id: group._id,
        pId: 'groups',
        value: group._id,
        title: group.name,
        label: group.name,
        level: 1,
      })) || []
    if (groupNodes.length > 0)
      groupNodes.unshift({
        id: 'groups',
        selectable: false,
        value: 'groups',
        title: t({
          id: 'modal.assign_courses.form.users.groups',
          message: 'Groepen',
        }),
        label: t({
          id: 'modal.assign_courses.form.users.groups',
          message: 'Groepen',
        }),
        level: 0,
      })

    return [...hierarchyNodes, ...userNodes, ...groupNodes]
  }, [usersData, groupsData, courses, hierarchy])

  const [form] = Form.useForm()
  const mandatoryField = Form.useWatch('mandatory', form)
  const deadlineField = Form.useWatch('deadline', form)
  const remindersField = Form.useWatch('reminders', form)

  /**
   * Calculate and set reminders date field
   */
  useEffect(() => {
    for (let i = 0; i < remindersField?.length; i++) {
      const date =
        !Number.isNaN(remindersField[i]?.time_amount) &&
        remindersField[i]?.time_unit
          ? dayjs(deadlineField)
              .startOf('day')
              .subtract(
                remindersField[i].time_amount,
                remindersField[i].time_unit
              )
              .toISOString()
          : undefined
      form.setFieldValue(['reminders', i, 'date'], date)
    }
  }, [remindersField, deadlineField, form])

  const editSuccessMessage = t({
    id: 'modal.assign_courses.edit.success',
    message: 'Toewijzing succesvol aangepast',
  })

  const newSuccessMessage = (length: number) => (
    <Plural
      id="modal.assign_courses.new.success"
      one="Opleiding succesvol toegewezen"
      other="Opleidingen succesvol toegewezen"
      value={length}
    />
  )

  const loading =
    groupsLoading || usersLoading || coursesLoading || hierarchyLoading

  return (
    <Modal
      forceRender
      title={
        subject
          ? t({
              id: 'modal.assign_courses.edit.title',
              message: 'Toewijzing aanpassen',
            })
          : t({
              id: 'modal.assign_courses.new.title',
              message: 'Opleidingen toewijzen',
            })
      }
      open={visible}
      okText={
        subject
          ? t({
              id: 'action.update',
              message: 'Wijzigen',
            })
          : t({
              id: 'action.assign',
              message: 'Toewijzen',
            })
      }
      cancelText={t({
        id: 'action.cancel',
        message: 'Annuleren',
      })}
      onOk={() => form.submit()}
      onCancel={() => onVisibilityChange(false)}
      okButtonProps={{ loading: assigning }}
    >
      {loading && <LoadSection />}
      <Form
        hidden={loading}
        form={form}
        initialValues={
          subject || { courses: course ? [course] : [], users: users || [] }
        }
        autoComplete="off"
        onFinish={async () => {
          const values = await form.validateFields()

          if (subject) {
            onUpdate?.({
              mandatory: !!values.mandatory,
              reminders: values.reminders ?? [],
              deadline: values.deadline
                ? new Date(
                    new Date(values.deadline).setHours(0, 0, 0, 0)
                  ).toISOString() || null
                : null,
              message: values.message,
            })
              .then(() => {
                notification.success({
                  message: editSuccessMessage,
                })
                onVisibilityChange(false)
                form.resetFields()
              })
              .catch(errorNotifierFn)
          } else {
            onAssign?.({
              courses: values.courses,
              assignees: values.users,
              mandatory: !!values.mandatory,
              notification: !!values.notification,
              reminders: values.reminders,
              deadline: values.deadline
                ? new Date(
                    new Date(values.deadline).setHours(0, 0, 0, 0)
                  ).toISOString() || null
                : null,
              message: values.message,
            })
              .then(() => {
                // SUCCESS
                notification.success({
                  message: newSuccessMessage(values.courses.length),
                })
                onVisibilityChange(false)
                form.resetFields()
              })
              .catch(errorNotifierFn)
          }
        }}
      >
        <Form.Item
          name="courses"
          hidden={!!subject}
          label={t({
            id: 'modal.assign_courses.form.label.courses',
            message: 'Opleidingen',
          })}
          labelCol={{ span: 8 }}
          required={!subject}
          rules={[
            {
              required: !subject,
              message: t({
                id: 'modal.assign_courses.form.validation.courses',
                message: 'Selecteer minimaal 1 opleiding',
              }),
            },
          ]}
        >
          <Select
            mode="multiple"
            loading={coursesLoading}
            allowClear
            style={{ width: '100%' }}
            optionFilterProp={'label'}
            placeholder={t({
              id: 'modal.assign_courses.form.placeholder.courses',
              message: 'Selecteer opleidingen',
            })}
            options={
              courses?.fetchBranchAccessibleCourses.results.map((course) => ({
                label: course.translation.name,
                value: course._id,
              })) || []
            }
          />
        </Form.Item>
        <Form.Item
          name="users"
          hidden={!!subject}
          label={t({
            id: 'modal.assign_courses.form.label.users',
            message: 'Toewijzen aan',
          })}
          labelCol={{ span: 8 }}
          required={!subject}
          rules={[
            {
              required: !subject,
              message: t({
                id: 'modal.assign_courses.form.validation.users',
                message: 'Selecteer minimaal 1 gebruiker of groep',
              }),
            },
          ]}
        >
          <TreeSelect
            multiple
            treeLine={true}
            showSearch
            treeDataSimpleMode
            loading={usersLoading || groupsLoading || hierarchyLoading}
            allowClear
            dropdownMatchSelectWidth={false}
            dropdownStyle={{
              maxHeight: 400,
              overflow: 'auto',
            }}
            filterTreeNode={(input, option) =>
              (option.title as string)
                ?.toLowerCase()
                .includes(input.toLowerCase())
            }
            treeNodeLabelProp="label"
            style={{ width: '100%' }}
            placeholder={t({
              id: 'modal.assign_courses.form.placeholder.users',
              message: 'Selecteer gebruikers of groepen',
            })}
            treeData={treeData}
          />
        </Form.Item>
        <Form.Item
          name="mandatory"
          label={t({
            id: 'modal.assign_courses.form.label.mandatory',
            message: 'Verplicht',
          })}
          labelCol={{ span: 8 }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          hidden={!mandatoryField}
          name={'deadline'}
          label={t({
            id: 'modal.assign_courses.form.label.deadline',
            message: 'Deadline',
          })}
          labelCol={{ span: 8 }}
        >
          <DatePicker
            allowClear={true}
            format="DD/MM/YYYY"
            style={{ width: '100%' }}
            disabledDate={(current) => current.isBefore(new Date())}
            showToday={false}
          />
        </Form.Item>
        <Form.Item
          name={'message'}
          label={t({
            id: 'modal.assign_courses.form.label.message',
            message: 'Bericht',
          })}
          labelCol={{ span: 8 }}
        >
          <Input.TextArea
            placeholder={t({
              id: 'modal.assign_courses.form.placeholder.message',
              message: 'Dit veld is optioneel.',
            })}
          />
        </Form.Item>
        <Form.Item
          name="notification"
          hidden={!!subject}
          label={t({
            id: 'modal.assign_courses.form.label.notification',
            message: 'Stuur een melding',
          })}
          labelCol={{ span: 8 }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t({
            id: 'modal.assign_courses.form.label.reminders',
            message: 'Herinneringen',
          })}
          labelCol={{ span: 8 }}
          hidden={!deadlineField}
          style={{ marginBottom: 0 }}
        >
          <Form.List name="reminders">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} style={{ display: 'flex' }} align="baseline">
                    <Form.Item
                      {...restField}
                      name={[name, 'time_amount']}
                      initialValue={1}
                      rules={[
                        {
                          required: true,
                          message: t({
                            id: 'modal.assign_courses.form.validation.reminder_time_amount',
                            message: 'Verplicht',
                          }),
                        },
                      ]}
                    >
                      <InputNumber style={{ maxWidth: 72 }} min={0} max={99} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'time_unit']}
                      rules={[
                        {
                          required: true,
                          message: t({
                            id: 'modal.assign_courses.form.validation.reminder_time_unit',
                            message: 'Verplicht',
                          }),
                        },
                      ]}
                      initialValue={'days'}
                    >
                      <Select
                        options={[
                          {
                            label: t({
                              id: 'modal.assign_courses.form.reminder_time_unit.hours',
                              message: 'uren',
                            }),
                            value: 'hours',
                          },
                          {
                            label: t({
                              id: 'modal.assign_courses.form.reminder_time_unit.days',
                              message: 'dagen',
                            }),
                            value: 'days',
                          },
                          {
                            label: t({
                              id: 'modal.assign_courses.form.reminder_time_unit.weeks',
                              message: 'weken',
                            }),
                            value: 'weeks',
                          },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Trans id="modal.assign_courses.form.reminder_before">
                        voor de deadline
                      </Trans>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'date']}
                      rules={[
                        {
                          required: true,
                          message: t({
                            id: 'modal.assign_courses.form.validation.deadline',
                            message: 'Verplicht',
                          }),
                        },
                      ]}
                      hidden
                    >
                      <Input />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    <Trans id="modal.assign_courses.form.add_reminder">
                      Voeg een herinnering toe
                    </Trans>
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
      </Form>
      {contextHolder}
    </Modal>
  )
}
