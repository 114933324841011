/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  MinusCircleOutlined,
  PlusOutlined,
  DownloadOutlined,
} from '@ant-design/icons'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import Uppy from '@uppy/core'
import { Dashboard } from '@uppy/react'
import Tus from '@uppy/tus'
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Form,
  Input,
  InputNumber,
  List as AntList,
  message,
  Modal,
  notification,
  PageHeader,
  Row,
  Space,
  Divider,
  Switch,
  Tooltip,
} from 'antd'
import Meta from 'antd/lib/card/Meta'
import { useForm } from 'antd/lib/form/Form'
import { Store } from 'antd/lib/form/interface'
import dayjs, { extend } from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import { cloneDeep } from 'lodash-es'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  VideoUnit,
  CreateVideoUnitMutation,
  CreateVideoUnitMutationVariables,
  VideoUnitInput,
  CloudflareVideosQuery,
  CloudflareVideo,
} from 'apps/lms-front/src/generated/graphql'
import { Can } from 'apps/lms-front/src/modules/auth/components/Can'
import { useBranch } from 'apps/lms-front/src/modules/auth/hooks/use-branch'
import { LoadSection } from 'apps/lms-front/src/modules/core/components/LoadScreen'
import { InputSearch } from 'apps/lms-front/src/modules/shared/components/input-search/InputSearch'
import { RichEditor } from 'apps/lms-front/src/modules/shared/components/rich-editor/RichEditor'
import { errorNotifierFn } from 'apps/lms-front/src/modules/shared/helpers/error-notifier'
import { PageProps } from 'apps/lms-front/src/modules/shared/interfaces/page.interface'
import { Content } from 'apps/lms-front/src/modules/shared/layout/Layout.style'

import { purgeProperties } from '../../../helpers/purge-properties'

import CREATE_VIDEO_UNIT_MUTATION from './../../../mutations/create-video-unit.graphql'
import UPDATE_VIDEO_UNIT_MUTATION from './../../../mutations/update-video-unit.graphql'
import CLOUDFLARE_VIDEOS_QUERY from './../../../queries/cloudflare-videos.graphql'

extend(duration)
extend(relativeTime)

interface Props extends PageProps {
  className?: string
  unit?: VideoUnit
}

const StListItem = styled((props) => <AntList.Item {...props} />)`
  padding-left: 1rem;
  border-radius: 0.5rem;
  &:hover {
    background-color: #f0f0f0;
  }
`
export const CourseEditVideoUnit = ({ route, unit }: Props) => {
  const branch = useBranch()
  const navigate = useNavigate()
  const [videoPickerVisible, setVideoPickerVisible] = useState(false)
  const [videoUploaderVisible, setVideoUploaderVisible] = useState(false)
  const [libraryVideo, setLibraryVideo] = useState<
    CloudflareVideo | undefined
  >()
  const [query, setQuery] = useState<string | undefined>()
  const [uppyClient, setUppyClient] = useState<Uppy>()

  const [createUnit, { loading: creating }] =
    useMutation<CreateVideoUnitMutation>(CREATE_VIDEO_UNIT_MUTATION)

  const [updateUnit, { loading: updating }] =
    useMutation<CreateVideoUnitMutation>(UPDATE_VIDEO_UNIT_MUTATION, {
      variables: {
        id: unit?._id,
      },
    })

  const [
    loadCloudflareVideos,
    { data: cloudflareVideos, loading: fetchingVideos },
  ] = useLazyQuery<CloudflareVideosQuery>(CLOUDFLARE_VIDEOS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      search: query,
    },
  })

  const { id, section } = useParams()

  const [form] = useForm()
  const [formDirty, setFormDirty] = useState<boolean>(false)
  const [showExplanationField, setShowExplanationField] = useState<number[]>([])

  useEffect(() => {
    if (unit?.cf_stream?.uid)
      setLibraryVideo({
        uid: unit.cf_stream.uid,
        thumbnail: unit.cf_stream.thumbnail,
        signedBucketURL: unit.cf_stream.signedBucketURL,
        duration: unit.cf_stream.duration,
        meta: { name: unit.cf_stream.meta.name },
      } as CloudflareVideo)
    form.setFieldValue('cf_stream_id', unit?.cf_stream?.uid)
  }, [unit, loadCloudflareVideos, form])

  const handleVideoSelected = (video: CloudflareVideo) => {
    form.setFieldsValue({ cf_stream_id: video.uid })
    setLibraryVideo(video)
    setFormDirty(true)
    setVideoPickerVisible(false)
  }

  const cleanedUnit = useMemo(() => {
    if (unit) {
      const clone = cloneDeep(unit)
      purgeProperties(clone, ['__typename'])
      return clone as Store
    }
    return undefined
  }, [unit])

  useEffect(() => {
    const getUppyClient = async () => {
      return new Uppy({ debug: true, autoProceed: true }).use(Tus, {
        endpoint: `${
          import.meta.env.NX_BACKEND_URL
        }/api/files/generateCloudflareTusURL`,
        chunkSize: 150 * 1024 * 1024,
        async onBeforeRequest(req) {
          if (req.getURL().includes('generateCloudflareTusURL'))
            req.setHeader('Upload-Creator', branch?._id)
        },
        async onAfterResponse(_, res) {
          if (res.getHeader('stream-media-id')) {
            const cf_stream_id = res.getHeader('stream-media-id')
            form.setFieldsValue({ cf_stream_id })
            setLibraryVideo(
              (v) =>
                ({
                  ...v,
                  uid: cf_stream_id,
                }) as CloudflareVideo
            )
          }
        },
      })
    }
    getUppyClient().then((client) => {
      client.on('file-added', (file) => {
        setLibraryVideo({
          meta: { name: file.name },
        } as CloudflareVideo)
      }),
        client.on('upload-success', () => {
          loadCloudflareVideos()
          setVideoUploaderVisible(false)
          message.info(
            t({
              id: 'course.unit.form.upload.video.success',
              message:
                'De upload was succesvol. Het kan enkele minuten duren voor de video in jouw bibliotheek beschikbaar is.',
            })
          )
        })
      setUppyClient(client)
    })
    return () => uppyClient?.close({ reason: 'unmount' })
  }, [])

  const MAX_CHARACTERS = {
    NAME: 100,
  }

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={route.label}
        subTitle={route.description}
        extra={[
          unit ? (
            <Button
              disabled={updating || creating}
              key="3"
              onClick={() =>
                navigate(`/courses/${unit.course_id}/edit-content`)
              }
            >
              <Trans id="course.edit_contents.back_to_overview">
                Terug naar overzicht
              </Trans>
            </Button>
          ) : (
            <Button
              loading={updating || creating}
              onClick={() => navigate(-1)}
              key="2"
              disabled={updating || creating}
            >
              <Trans id="action.cancel">Annuleren</Trans>
            </Button>
          ),
          <Button
            loading={updating || creating}
            disabled={!formDirty || creating || updating}
            onClick={() => form.submit()}
            key="1"
            type="primary"
          >
            <Trans id="action.save">Opslaan</Trans>
          </Button>,
        ]}
      />
      <Content>
        <Row justify="center" style={{ flex: 1 }}>
          <Col xs={24}>
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{
                ...cleanedUnit,
                questions_trigger_pct: cleanedUnit?.questions_trigger_pct * 100,
              }}
              onFinishFailed={(error) => {
                error.errorFields.forEach((field) => {
                  field.errors.forEach((error) => {
                    notification.error({
                      message: error,
                    })
                  })
                })
              }}
              onFinish={async (variables: VideoUnitInput) => {
                if (
                  (variables.cf_stream_id && variables.external_url) ||
                  (!variables.cf_stream_id && !variables.external_url)
                ) {
                  notification.error({
                    message: t({
                      id: 'course.unit.form.validation.video',
                      message:
                        'Gelieve een video uit de bibliotheek te kiezen of een externe link in te voeren.',
                    }),
                  })
                  return
                }
                if (unit) {
                  updateUnit({
                    variables: {
                      ...variables,
                      _id: cleanedUnit?._id,
                      questions_trigger_pct: variables.questions_trigger_pct
                        ? variables.questions_trigger_pct / 100
                        : undefined,
                    },
                  })
                    .then(() => {
                      notification.success({
                        message: t({
                          id: 'course.unit.edit.success',
                          message: 'Onderdeel succesvol opgeslagen',
                        }),
                      })
                      setFormDirty(false)
                    })
                    .catch(errorNotifierFn)
                } else {
                  createUnit({
                    variables: {
                      name: variables.name,
                      external_url: variables.external_url,
                      cf_stream_id: variables.cf_stream_id,
                      annotation: variables.annotation,
                      courseId: id,
                      sectionId: section,
                      questions: variables.questions || [],
                      survey_questions: variables.survey_questions || [],
                      questions_trigger_pct: variables.questions_trigger_pct
                        ? variables.questions_trigger_pct / 100
                        : 0.9,
                    } as CreateVideoUnitMutationVariables,
                  })
                    .then((result) => {
                      notification.success({
                        message: t({
                          id: 'course.unit.create.success',
                          message: 'Onderdeel succesvol opgeslagen',
                        }),
                      })
                      const id = result.data?.addVideoUnit.course_id
                      navigate(`/courses/${id}/edit-content`, {
                        replace: true,
                      })
                    })
                    .catch(errorNotifierFn)
                }
              }}
              onFieldsChange={() => setFormDirty(true)}
              autoComplete="off"
            >
              <Form.Item
                label={t({
                  id: 'course.unit.form.label.name',
                  message: 'Naam',
                })}
                name="name"
                rules={[
                  {
                    required: !!unit,
                    message: t({
                      id: 'course.unit.form.validation.name',
                      message:
                        'Gelieve een naam voor dit onderdeel in te vullen',
                    }),
                  },
                  {
                    max: MAX_CHARACTERS.NAME,
                    message: t({
                      id: 'course.unit.form.validation.max_characters',
                      message: `Gelieve onder de ${MAX_CHARACTERS.NAME} tekens te blijven`,
                    }),
                  },
                ]}
              >
                <Input tabIndex={0} />
              </Form.Item>
              <Form.Item
                hidden={!!libraryVideo}
                label={t({
                  id: 'course.unit.form.label.external_video_url',
                  message: 'Video URL',
                })}
                name="external_url"
              >
                <Input />
              </Form.Item>
              <Form.Item
                hidden
                label={t({
                  id: 'course.unit.form.label.cf_stream_id',
                  message: 'Cloudflare Stream ID',
                })}
                name="cf_stream_id"
                initialValue={unit?.cf_stream_id}
              >
                <Input />
              </Form.Item>
              <Form.Item wrapperCol={{ sm: { offset: 8, span: 16 } }}>
                {libraryVideo && (
                  <Card style={{ marginBottom: 24 }}>
                    <Meta
                      avatar={
                        <Avatar
                          shape="square"
                          src={`${libraryVideo.thumbnail}?time=5s`}
                          style={{ boxShadow: '5px 5px 40px rgba(0,0,0,0.12)' }}
                        />
                      }
                      title={
                        <Space>
                          <span>{libraryVideo.meta.name}</span>
                          <Can
                            I={PermissionAction.DOWNLOAD}
                            a={PermissionObjectType.VIDEO}
                          >
                            {libraryVideo.signedBucketURL && (
                              <Button
                                type={'text'}
                                onClick={() => {
                                  window.open(
                                    `${libraryVideo.signedBucketURL!}&response-content-disposition=attachment`,
                                    '_blank'
                                  )
                                }}
                              >
                                <Tooltip
                                  title={t({
                                    id: 'action.download',
                                    message: 'Downloaden',
                                  })}
                                >
                                  <DownloadOutlined />
                                </Tooltip>
                              </Button>
                            )}
                          </Can>
                        </Space>
                      }
                      description={
                        libraryVideo.duration
                          ? dayjs
                              .duration(libraryVideo.duration, 'seconds')
                              .humanize()
                          : undefined
                      }
                    />
                  </Card>
                )}
                <Space>
                  <Button
                    onClick={() => {
                      setVideoPickerVisible(true)
                      loadCloudflareVideos()
                    }}
                  >
                    <Trans id="course.unit.form.action.select_video">
                      Video uit bibliotheek kiezen
                    </Trans>
                  </Button>
                  <Button
                    onClick={() => {
                      setVideoUploaderVisible(true)
                    }}
                  >
                    <Trans id="course.unit.form.action.upload_video">
                      Uploaden naar de bibliotheek
                    </Trans>
                  </Button>
                </Space>
                {libraryVideo && (
                  <Button
                    type={'text'}
                    onClick={() => {
                      form.resetFields(['cf_stream_id'])
                      setLibraryVideo(undefined)
                    }}
                  >
                    <Trans id="course.unit.form.action.enter_video_url">
                      Externe link invoeren
                    </Trans>
                  </Button>
                )}
              </Form.Item>
              <Form.Item
                label={t({
                  id: 'course.unit.form.label.annotation',
                  message: 'Annotatie',
                })}
                name="annotation"
              >
                <RichEditor />
              </Form.Item>
              <Form.List
                name="questions"
                rules={[
                  {
                    validator: async (_, questions) => {
                      if (!questions || questions.length === 0) return true
                      const error = questions.some(
                        (question: { answers: { correct: boolean }[] }) =>
                          (question.answers && question.answers.length < 2) ||
                          !question.answers
                      )

                      if (error)
                        throw new Error(
                          t({
                            id: 'course.unit.form.validation.quiz.min_answers',
                            message:
                              'Gelieve minstens twee antwoorden op te geven',
                          })
                        )
                      else return true
                    },
                    message: t({
                      id: 'course.unit.form.validation.quiz.min_answers',
                      message: 'Gelieve minstens twee antwoorden op te geven',
                    }),
                  },
                  {
                    validator: async (_, questions) => {
                      if (!questions || questions.length === 0) return true
                      const error = questions.some(
                        (question: { answers: { is_correct: boolean }[] }) =>
                          !question.answers.some((answer) => answer.is_correct)
                      )
                      if (error)
                        throw new Error(
                          t({
                            id: 'course.unit.form.validation.quiz.correct_answer',
                            message:
                              'Gelieve minstens één correct antwoord op te geven',
                          })
                        )
                      else return true
                    },
                    message: t({
                      id: 'course.unit.form.validation.quiz.correct_answer',
                      message:
                        'Gelieve minstens één correct antwoord op te geven',
                    }),
                  },
                ]}
              >
                {(fields, { add, remove }) => (
                  <>
                    <Row>
                      <Col span={16} offset={8}>
                        {fields.length > 0 && (
                          <h3>
                            <Trans id="course.unit.form.video.label.quiz_questions">
                              Controlevragen
                            </Trans>
                            :
                          </h3>
                        )}
                        <Collapse
                          ghost={fields.length === 0}
                          defaultActiveKey={0}
                        >
                          {fields.map(({ key, name, ...restField }, i, arr) => (
                            <Collapse.Panel
                              key={key}
                              forceRender={true}
                              header={
                                <div
                                  style={{ flex: 1, display: 'flex' }}
                                  onKeyDown={(e) => e.stopPropagation()}
                                  onKeyUp={(e) => e.stopPropagation()}
                                  onKeyPress={(e) => e.stopPropagation()}
                                  onClick={(e) => e.stopPropagation()}
                                  role={'menuitem'}
                                  tabIndex={-1}
                                >
                                  <Form.Item
                                    {...restField}
                                    style={{
                                      flex: 1,
                                      marginTop: -4,
                                      marginBottom: -4,
                                    }}
                                    wrapperCol={{ span: 24 }}
                                    name={[name, 'question']}
                                    rules={[
                                      {
                                        required: true,
                                        message: t({
                                          id: 'course.unit.form.validation.quiz.question',
                                          message:
                                            'Gelieve een vraag in te vullen',
                                        }),
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder={t({
                                        id: 'course.unit.form.placeholder.quiz.question',
                                        message: 'Vul hier de vraag in',
                                      })}
                                    />
                                  </Form.Item>
                                </div>
                              }
                              style={{
                                marginBottom: i === arr.length - 1 ? 24 : 0,
                              }}
                              extra={
                                <MinusCircleOutlined
                                  style={{
                                    padding: 12,
                                    margin: -12,
                                    marginLeft: 0,
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    remove(name)
                                  }}
                                />
                              }
                            >
                              <Form.List name={[name, 'answers']}>
                                {(fields, { add, remove }) => (
                                  <>
                                    {fields.map(
                                      ({ key, name, ...restField }) => (
                                        <div
                                          key={key}
                                          style={{
                                            display: 'flex',
                                            alignItems: 'baseline',
                                            marginBottom: -8,
                                            paddingLeft: 24,
                                          }}
                                        >
                                          <MinusCircleOutlined
                                            style={{
                                              margin: -8,
                                              padding: 8,
                                              marginRight: 4,
                                            }}
                                            onClick={() => remove(name)}
                                          />
                                          <Form.Item
                                            {...restField}
                                            style={{ flex: 1 }}
                                            wrapperCol={{ span: 24 }}
                                            name={[name, 'answer']}
                                            rules={[
                                              {
                                                required: true,
                                                message: t({
                                                  id: 'course.unit.form.validation.quiz.answer',
                                                  message:
                                                    'Vul een antwoord in',
                                                }),
                                              },
                                            ]}
                                          >
                                            <Input
                                              placeholder={t({
                                                id: 'course.unit.form.placeholder.quiz.answer',
                                                message: 'Vul een antwoord in',
                                              })}
                                            />
                                          </Form.Item>
                                          <Form.Item
                                            {...restField}
                                            style={{ marginLeft: 24 }}
                                            name={[name, 'is_correct']}
                                            valuePropName="checked"
                                          >
                                            <Checkbox>
                                              <Trans id="course.unit.form.label.quiz.correct">
                                                Correct
                                              </Trans>
                                            </Checkbox>
                                          </Form.Item>
                                        </div>
                                      )
                                    )}
                                    <Form.Item
                                      wrapperCol={{ span: 24 }}
                                      style={{ paddingLeft: 48 }}
                                    >
                                      <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        block
                                      >
                                        <Space
                                          style={{ justifyContent: 'center' }}
                                        >
                                          <PlusOutlined />
                                          <Trans id="course.unit.form.action.quiz.add_answer">
                                            Antwoord toevoegen
                                          </Trans>
                                        </Space>
                                      </Button>
                                    </Form.Item>
                                    <Divider />
                                  </>
                                )}
                              </Form.List>
                              {!showExplanationField.includes(key) &&
                              !form.getFieldValue('questions')?.[key]
                                ?.explanation ? (
                                <Button
                                  type="text"
                                  onClick={() => {
                                    const updated = [
                                      ...showExplanationField,
                                      key,
                                    ]
                                    setShowExplanationField(updated)
                                  }}
                                  block
                                >
                                  <Space style={{ justifyContent: 'center' }}>
                                    <PlusOutlined />
                                    <Trans id="course.unit.form.action.quiz.add_explanation">
                                      Voeg uitleg bij antwoord toe
                                    </Trans>
                                  </Space>
                                </Button>
                              ) : (
                                <div
                                  key={key}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'baseline',
                                    marginBottom: -8,
                                    paddingLeft: 24,
                                  }}
                                >
                                  <Form.Item
                                    {...restField}
                                    style={{
                                      flex: 1,
                                      marginTop: -4,
                                    }}
                                    wrapperCol={{ span: 24 }}
                                    name={[name, 'explanation']}
                                  >
                                    <Input
                                      placeholder={t({
                                        id: 'course.unit.form.placeholder.quiz.explanation',
                                        message: 'Vul hier de uitleg in',
                                      })}
                                    />
                                  </Form.Item>
                                  <MinusCircleOutlined
                                    style={{
                                      marginLeft: 8,
                                    }}
                                    onClick={() => {
                                      // update the array of ids where the field should be shown
                                      const updatedItems =
                                        showExplanationField.filter(
                                          (i) => i !== key
                                        )
                                      setShowExplanationField(updatedItems)

                                      // clear value of field
                                      const updatedQuestions =
                                        form.getFieldValue('questions')

                                      updatedQuestions[name] = {
                                        ...updatedQuestions[name],
                                        explanation: undefined,
                                      }

                                      form.setFieldValue(
                                        'questions',
                                        updatedQuestions
                                      )

                                      // enable save button
                                      setFormDirty(true)
                                    }}
                                  />
                                </div>
                              )}
                            </Collapse.Panel>
                          ))}
                        </Collapse>

                        <Form.Item wrapperCol={{ span: 24 }}>
                          <Button type="dashed" onClick={() => add()} block>
                            <Space style={{ justifyContent: 'center' }}>
                              <PlusOutlined />
                              <Trans id="course.unit.form.action.video.quiz.add_question">
                                Controlevraag toevoegen
                              </Trans>
                            </Space>
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
              </Form.List>
              <Form.List name="survey_questions">
                {(fields, { add, remove }) => (
                  <>
                    <Row>
                      <Col span={16} offset={8}>
                        {fields.length > 0 && (
                          <h3>
                            <Trans id="course.unit.form.label.survey_questions">
                              Enquêtevragen
                            </Trans>
                            :
                          </h3>
                        )}
                        <Collapse
                          ghost={fields.length === 0}
                          defaultActiveKey={0}
                        >
                          {fields.map(({ key, name, ...restField }, i, arr) => (
                            <Collapse.Panel
                              key={key}
                              forceRender={true}
                              header={
                                <div
                                  style={{ flex: 1, display: 'flex' }}
                                  onKeyDown={(e) => e.stopPropagation()}
                                  onKeyUp={(e) => e.stopPropagation()}
                                  onKeyPress={(e) => e.stopPropagation()}
                                  onClick={(e) => e.stopPropagation()}
                                  role={'menuitem'}
                                  tabIndex={-1}
                                >
                                  <Form.Item
                                    {...restField}
                                    style={{
                                      flex: 1,
                                      marginTop: -4,
                                      marginBottom: -4,
                                    }}
                                    wrapperCol={{ span: 24 }}
                                    name={[name, 'question']}
                                    rules={[
                                      {
                                        required: true,
                                        message: t({
                                          id: 'course.unit.form.validation.survey.question',
                                          message:
                                            'Gelieve een vraag in te vullen',
                                        }),
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder={t({
                                        id: 'course.unit.form.placeholder.survey.question',
                                        message: 'Vul hier de vraag in',
                                      })}
                                    />
                                  </Form.Item>
                                </div>
                              }
                              style={{
                                marginBottom: i === arr.length - 1 ? 24 : 0,
                              }}
                              extra={
                                <MinusCircleOutlined
                                  style={{
                                    padding: 12,
                                    margin: -12,
                                    marginLeft: 0,
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    remove(name)
                                  }}
                                />
                              }
                            >
                              <Form.List name={[name, 'answers']}>
                                {(fields, { add, remove }) => (
                                  <>
                                    {fields.map(
                                      ({ key, name, ...restField }) => (
                                        <div
                                          key={key}
                                          style={{
                                            display: 'flex',
                                            alignItems: 'baseline',
                                            marginBottom: -8,
                                            paddingLeft: 24,
                                          }}
                                        >
                                          <MinusCircleOutlined
                                            style={{
                                              margin: -8,
                                              padding: 8,
                                              marginRight: 4,
                                            }}
                                            onClick={() => remove(name)}
                                          />
                                          <Form.Item
                                            {...restField}
                                            style={{ flex: 1 }}
                                            wrapperCol={{ span: 24 }}
                                            name={[name, 'answer']}
                                            rules={[
                                              {
                                                required: true,
                                                message: t({
                                                  id: 'course.unit.form.validation.survey.answer',
                                                  message:
                                                    'Vul een antwoord in',
                                                }),
                                              },
                                            ]}
                                          >
                                            <Input
                                              placeholder={t({
                                                id: 'course.unit.form.placeholder.survey.answer',
                                                message: 'Vul een antwoord in',
                                              })}
                                            />
                                          </Form.Item>
                                        </div>
                                      )
                                    )}
                                    <Form.Item
                                      wrapperCol={{ span: 24 }}
                                      style={{ paddingLeft: 48 }}
                                    >
                                      <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        block
                                      >
                                        <Space
                                          style={{ justifyContent: 'center' }}
                                        >
                                          <PlusOutlined />
                                          <Trans id="course.unit.form.action.survey.add_answer">
                                            Antwoord toevoegen
                                          </Trans>
                                        </Space>
                                      </Button>
                                    </Form.Item>
                                  </>
                                )}
                              </Form.List>
                            </Collapse.Panel>
                          ))}
                        </Collapse>

                        <Form.Item wrapperCol={{ span: 24 }}>
                          <Button type="dashed" onClick={() => add()} block>
                            <Space style={{ justifyContent: 'center' }}>
                              <PlusOutlined />
                              <Trans id="course.unit.form.action.video.survey.add_question">
                                Enquêtevraag toevoegen
                              </Trans>
                            </Space>
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
              </Form.List>
              <Form.Item
                label={t({
                  id: 'course.unit.form.label.questions_trigger_pct',
                  message: 'Vragen stellen op',
                })}
                name="questions_trigger_pct"
              >
                <InputNumber
                  min={1}
                  max={99}
                  placeholder={'60'}
                  addonAfter="%"
                ></InputNumber>
              </Form.Item>
              <Form.Item
                name="optional"
                label={t({
                  id: 'course.unit.form.label.optional',
                  message: 'Optioneel',
                })}
                tooltip={t({
                  id: 'course.unit.form.help.optional',
                  message:
                    'Dit lesonderdeel is niet verplicht om het attest van deze opleiding te behalen.',
                })}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <Form.Item wrapperCol={{ sm: { offset: 8, span: 16 } }}>
                <Button
                  loading={updating || creating}
                  disabled={!formDirty || updating || creating}
                  type="primary"
                  htmlType={'submit'}
                >
                  <Trans id="action.save">Opslaan</Trans>
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Content>
      <Modal
        open={videoUploaderVisible}
        maskClosable={false}
        closable={false}
        okText={t({
          id: 'action.upload',
          message: 'Upload',
        })}
        cancelText={t({
          id: 'action.cancel',
          message: 'Annuleren',
        })}
        okButtonProps={{ hidden: true }}
        onCancel={() => setVideoUploaderVisible(false)}
      >
        {uppyClient && <Dashboard uppy={uppyClient} />}
      </Modal>
      <Modal
        title={t({
          id: 'course.unit.action.select_video',
          message: 'Selecteer een video',
        })}
        open={videoPickerVisible}
        okText={t({
          id: 'action.add',
          message: 'Toevoegen',
        })}
        cancelText={t({
          id: 'action.cancel',
          message: 'Annuleren',
        })}
        okButtonProps={{ hidden: true }}
        onCancel={() => setVideoPickerVisible(false)}
      >
        <InputSearch
          style={{ marginBottom: 24 }}
          placeholder={t({
            id: 'course.unit.form.placeholder.video_search',
            message: 'Zoeken...',
          })}
          onSearch={(val: string) =>
            val && val.length > 0 ? setQuery(val) : setQuery(undefined)
          }
        />

        {fetchingVideos && <LoadSection />}
        {!fetchingVideos && cloudflareVideos?.fetchCloudflareVideos && (
          <>
            <AntList
              dataSource={cloudflareVideos.fetchCloudflareVideos}
              renderItem={(item) => (
                <StListItem
                  key={item.uid}
                  onClick={() => handleVideoSelected(item as CloudflareVideo)}
                  style={{ cursor: 'pointer' }}
                >
                  <AntList.Item.Meta
                    avatar={
                      <Avatar
                        shape="square"
                        src={`${item.thumbnail}?time=5s`}
                        style={{ boxShadow: '5px 5px 40px rgba(0,0,0,0.12)' }}
                      />
                    }
                    title={item.meta.name}
                    description={dayjs
                      .duration(item.duration, 'seconds')
                      .humanize()}
                  />
                </StListItem>
              )}
            />
          </>
        )}
      </Modal>
    </>
  )
}
